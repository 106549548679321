import { Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Select, Tooltip, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import Main from "../../../components/layout/Main";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiPath from "../../../constants/apiPath";
import ShowTotal from "../../../components/ShowTotal";
import moment from "moment";
import useDebounce from "../../../hooks/useDebounce";
import ImportForm from "../../../components/ImportForm";
import { DownloadExcel } from "../../../components/ExcelFile";

const Search = Input.Search;

function Index() {

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [importVisible, setImportVisible] = useState(false);
  const { confirm } = Modal;

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);

  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      render: (_, { type }) => type?.name ?? "-"
    },
    {
      title: "Brand",
      dataIndex: "make_id",
      key: "make_id",
      render: (_, {make}) => {
        return (
          make ? make.name : '-'
        )
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      className : 'customStatus',
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a> );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
           <Tooltip title="Update Model" color={"purple"} key={"updatemodel"}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i class="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusCarModel + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: apiPath.listCarModel + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const excelData = list.map((row) => ({
    "type": row?.type?.name,
    "brand": row?.make.name,
    "name": row?.name,
    "status": row?.is_active ? "Active" : "Inactive",
  }));

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Vehicle Model"
              extra={
                <>
                <Search
                  size="large"
                  onChange={onSearch}
                  value={searchText}
                  onPressEnter={onSearch}
                  placeholder="Search By Name"
                />

                <Button title="Export" onClick={(e) => DownloadExcel(excelData, 'Model')}>
                  <i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{'Export'}
                </Button>

                <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i class="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button>
                <Button onClick={(e) => { setVisible(true); setSearchText(''); } }>Add Vehicle Model</Button>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50', '600']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {importVisible && <ImportForm path={apiPath.importCarModel} sectionName={'Model'} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [type, setType] = useState([]);
  const [makeList, setMakeList] = useState([])
  const [loading, setLoading] = useState(false)
  const [typeLoading, setTypeLoading] = useState(false)
  const [makeLoading, setMakeLoading] = useState(false)
  
  const getType = () => {
    setTypeLoading(true);
    request({
      url: apiPath.carTypeList,
      method: 'GET',
      onSuccess: (data) => {
        setTypeLoading(false);
        setType(data.data)
      },
      onError: (error) => {
        setTypeLoading(false);
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  
  const getMakeList = () => {
    setMakeLoading(true);
    request({
      url: apiPath.carMakeList,
      method: 'GET',
      onSuccess: (data) => {
        setMakeLoading(false);
        setMakeList(data.data);
      },
      onError: (error) => {
        setMakeLoading(false);
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  useEffect(() => {
    getType();
    getMakeList();
    if (!data) return
    form.setFieldsValue({ ...data })
  }, [data])

  const onCreate = (values) => {
    const { name, make_id, type_id } = values
    const payload = {}
    setLoading(true)
    payload.name = name;
    payload.make_id = make_id;
    payload.type_id = type_id;
    request({
      url: `${data ? apiPath.addEditCarModel + "/" + data._id : apiPath.addEditCarModel}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Vehicle Model' : 'Create a New Vehicle Model'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="make-elt"
              name="make_id"
              label="Select Brand"
              rules={[{ required: true, message: 'Missing Brand Selection' }]}
            >

              {makeLoading ? (<Skeleton active />) : (
                <Select placeholder="Select Brand">
                  {makeList && makeList.length > 0 ? (
                    makeList.map((item, index) => (
                      <option key={item._id} value={item._id}>
                        <span className="cap"> {item.name} </span>
                      </option>
                    ))
                  ) : null}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="type-elt"
              name="type_id"
              label="Select Vehicle Type"
              rules={[{ required: true, message: 'Missing Vehicle Type Selection' }]}
            >

              {typeLoading ? (<Skeleton active />) : (
                <Select placeholder="Select Vehicle Type">
                  {type && type.length > 0 ? (
                    type.map((item, index) => (
                      <type key={index} value={item._id}>
                        <span className="cap"> {item.name} </span>
                      </type>
                    ))
                  ) : null}
                </Select>
              )}
              
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Model"
              name="name"
              rules={[
                { required: true, message: "Please Enter the model!" },
                { max: 50, message: "Model should not contain more then 50 characters!" },
                { min: 2, message: "Model should contain atleast 1 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Model Name"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
