import { Row, Col, Card, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { useParams } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import { ShowToast, Severty } from "../../helper/toast";
import moment from "moment";

const Success = () => {
    
    const params                    =   useParams();
    const { request }               =   useRequest()
    const [list, setList]           =   useState({});
    const [loading, setLoading]     =   useState(false);

    const fetchData = (id) => {
        setLoading(true);
        request({
          url: apiPath.paymentOrderView + "/" + id,
          method: 'GET',
          onSuccess: (data) => {
            setLoading(false);
            setList(data.data);
          },
          onError: (error) => {
            ShowToast(error, Severty.ERROR)
          }
        })
    }

    const updatePaymentData = (order_id) => {
        const payload = {}
        payload.order_id    =   order_id;
        request({
          url   : apiPath.updatePaymentOrder,
          method: 'POST',
          data  : payload,
          onSuccess: (data) => {
            console.log("success");
          },
          onError: (error) => {
            console.log("error", error);
          },
        })
    };

    useEffect(() => {
        fetchData(params.order_id)
        updatePaymentData(params.order_id)
    }, [])
    
    return (
        <div className="paymentMain h-100 d-flex align-items-center justify-content-center">
            <Row className="justify-content-center">
                <Col span={24} lg={12} className="">
                    <Card className="paymentCard">
                        {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                            <>
                                <div class="success-checkmark">
                                    <div class="check-icon">
                                        <span class="icon-line line-tip"></span>
                                        <span class="icon-line line-long"></span>
                                        <div class="icon-circle"></div>
                                        <div class="icon-fix"></div>
                                    </div>
                                </div>
                                <div className="mainTagLine">Payment Successful!</div>
                                <div className="tagLine">Thank you! Your Payment of OMR {list.total} has been received.</div>
                                <div className="orderId">Order ID : {list && list.random_order_id} &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; Order Type : {list && list.order_type}</div>
                                <div className="orderId">Ordered Date : {list && list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : null }</div>
                            </>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Success;