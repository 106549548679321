
import { useState, useEffect, useRef, useContext } from "react";
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { db, firebase, realDb } from "../config/firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

const useFirebase = () => {

    const [washerLiveLocations, setWasherLiveLoactions] = useState([])

    const [messaging, setMessaging] = useState()
    const [fcmToken, setFcmToken] = useState();

    useEffect(() => {
        console.log(messaging, 'messaging');
        if (messaging) return
        const init = async () => {
            const supported = await isSupported()
            if (supported) {
                const messaging = getMessaging(firebase);
                setMessaging(messaging)
                const token = await requestForToken(messaging)
                setFcmToken(token)
            }
        }
        init()

    }, [messaging])


    const getWasherLiveLocation = () => {
        const starCountRef = ref(realDb, '/');
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            setWasherLiveLoactions(Object.values(data))
            console.log(data, 'data', Object.values(data));
        });
    }



    const requestForToken = (messaging) => {
        if (!messaging) return ''
        return new Promise((resolve, reject) => {
            getToken(messaging, { vapidKey: `BPOV6o7ivRe-Y7acwk3p_caci0SO8MOEWt7m4CTXqnQ_JV_uY_6fpfHVA0q6b6Ee7u9OtypQcEvOzHaxXHWLh6A` })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        resolve(currentToken)
                    } else {
                        requestPermission()
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    requestPermission()
                    console.log('An error occurred while retrieving token. ', err);
                });
        })
    }

    function requestPermission() {
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                requestForToken()
            } else {
                console.log('Permission for notifications has been denied');
            }
        });
    }

    return { getWasherLiveLocation, washerLiveLocations, requestForToken, fcmToken }
}

export default useFirebase