import { Layout, Row, Col } from "antd";
import { HeartFilled } from "@ant-design/icons";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const currentYear   =  new Date().getFullYear();

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <div className="copyright">
        Copyright © {currentYear}. All Rights Reserved
      </div>
    </AntFooter>
  );
}

export default Footer;
