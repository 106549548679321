import { Row, Col, Card, Table, Button, Modal, Form, Input, Tooltip, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import { PRICE } from "../../constants/conts";
import apiPath from "../../constants/apiPath";
import moment from 'moment';
const Search = Input.Search;

function Index() {

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;
  const navigate = useNavigate();


  const view = (record) => {
    navigate(`/cash-entries/view/${record._id}`)
  }

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      render: (_, { order_id }) => {
        return order_id && order_id.random_order_id ? order_id.random_order_id : '-';
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customer_id",
      key: "customer_id",
      render: (_, { customer_id }) => {
        return customer_id ? <Link className="cap" to={`/customer/view/${customer_id._id}`}>{customer_id.name}</Link> : '-';
      },
    },
    {
      title: "Washer Received",
      dataIndex: "washer_id",
      key: "washer_id",
      render: (_, { washer_id }) => {
        return washer_id ? <Link className="cap" to={`/washer/view/${washer_id._id}`}>{washer_id.name}</Link> : '-';
      },
    },
    {
      title: "Cash Amount (" + PRICE + ")",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return amount ? amount : '-';
      },
    },
    {
      title: "Customer Location",
      dataIndex: "order_id",
      key: "order_id",
      render: (_, { order_id }) => {
        return order_id && order_id.address ? order_id.address : '-';
      },
    },
    /* {
      title: "Date & Time of Service",
      dataIndex: "date_time_service",
      key: "date_time_service",
      render: (_, { date_time_service }) => moment(date_time_service).format('DD-MMM-YYYY, hh:mm A')
    }, */ 
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="View Entry" color={"purple"} key={"veewentry"}>
              <Button onClick={(e) => view(record)}>
                <i class="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: apiPath.listCashEntry + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Cash Entry Management"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Order ID"
                  />
                  <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add Cash Entry</Button>
                </>
              }
            >
              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [customerList, setCustomerList] = useState([])
  const [washerList, setWasherList] = useState([])

  useEffect(() => {
    getOrderList();
    getCustomerList();
    getWasherList();
    if (!data) return
    form.setFieldsValue({ ...data })
  }, [data])

  const getOrderList = () => {
    request({
      url: apiPath.orderList,
      method: 'GET',
      onSuccess: (data) => {
        setOrderList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getCustomerList = () => {
    request({
      url: apiPath.customerList,
      method: 'GET',
      onSuccess: (data) => {
        setCustomerList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getWasherList = () => {
    request({
      url: apiPath.washerList,
      method: 'GET',
      onSuccess: (data) => {
        setWasherList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const onCreate = (values) => {
    const { order_id, washer_id, amount, customer_id, date_time_service } = values
    const payload = {}
    setLoading(true)
    payload.order_id = order_id;
    payload.washer_id = washer_id;
    payload.amount = amount;
    payload.customer_id = customer_id;
    // payload.date_time_service = date_time_service;
    request({
      url: `${data ? apiPath.addEditCashEntry + "/" + data._id : apiPath.addEditCashEntry}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Cash Entry' : 'Create a New Cash Entry'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="washer-elt"
              name="washer_id"
              label="Select Washer"
              rules={[{ required: true, message: 'Missing Washer ID' }]}
            >
              <Select placeholder="Select Washer">
                {washerList && washerList && washerList.length > 0 ? washerList.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name}
                  </option>
                )) : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="customer-elt"
              name="customer_id"
              label="Select Customer"
              rules={[{ required: true, message: 'Missing Customer ID' }]}
            >
              <Select placeholder="Select Customer">
                {customerList.length > 0 ? customerList.map((item, index) => ( <option key={index} value={item._id}> {item.name} </option> )) : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="order-elt"
              name="order_id"
              label="Select Order ID (Multiple)"
              rules={[{ required: true, message: 'Missing Order ID' }]}
            >
              <Select mode="multiple" placeholder="Select Order ID">
                {orderList.length > 0 ? orderList.map((item, index) => ( <option key={index} value={item._id}> {item.random_order_id} </option> )) : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={'Amount (' + PRICE + ')'}
              name="amount"
              rules={[
                { required: true, message: "Please Enter the amount!" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Digits Are Allowed" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Amount" />
            </Form.Item>
          </Col>

          {/* <Col span={24}>
            <Form.Item
              label="Date & Time of Service"
              name="date_time_service"
              rules={[
                { required: true, message: "Please select date & time of service!" },
              ]}
            >
              <DatePicker format="YYYY-MM-DD HH:mm" showTime placeholder="Enter Select Date & Time of Service" />
            </Form.Item>
          </Col> */}

        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
