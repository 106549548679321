import { Row, Col, Card, Button, Image, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import { PRICE } from "../../constants/conts";
import notfound from "../../assets/images/not_found.png"
import moment from "moment";

function View() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url: apiPath.viewProductService + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <Card title="Product Service Details">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
     
            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Image:</h5>
                  <h6><Image width={120} height={120} className="float-right" src={list.image ? list.image : notfound} /></h6> 
                </div>

                <div className="view-inner-cls">
                  <h5>Name:</h5>
                  <h6>{list.name ? <span className="cap">{list.name}</span> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Is Featured:</h5>
                  <h6>{list.is_featured ? 'Yes' : 'No'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Time (Min):</h5>
                  <h6>{list.time}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{'Price ('+PRICE +')'}:</h5>
                  <h6>{list.price}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Vehicle Type:</h5>
                  <h6>{list && list.vehicle_type && list.vehicle_type.name ? list.vehicle_type.name : "-"}</h6>
                </div>

                {/* <div className="view-inner-cls">
                  <h5>Vehicle Brand:</h5>
                  <h6>{list && list.vehicle_make && list.vehicle_make.name ? list.vehicle_make.name : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Vehicle Model:</h5>
                  <h6>{list && list.vehicle_model && list.vehicle_model.name ? list.vehicle_model.name : "-"}</h6>
                </div> */}

                <div className="view-inner-cls">
                  <h5>Features:</h5>
                  <Row className="text-right">
                    <Col span={24}>
                      {list && list.feature_list && list.feature_list.length > 0 && list.feature_list.map((item, index) => 
                        <h6 className="mt-1">{index+1}) {item} &nbsp;</h6>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-default" to="/product-service/">Back</Link>
                </div>

              </div>
            }
          </Col>
        </Row>
      </Card>
    </Main>
  );
}

export default View;
