import { Row, Col, Card, Table, Button, Select, Modal, Form, Input, Tag, Space, DatePicker, Tooltip } from "antd";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import useDebounce from "../../hooks/useDebounce";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import { PRICE } from "../../constants/conts";
import apiPath from "../../constants/apiPath";
const Search = Input.Search;

function Index() {

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;
  const navigate = useNavigate();

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);

  };

  const view = (record) => {
    navigate(`/discount/view/${record._id}`)
  }

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (_, { code, _id }) => {
        if (code) {
          return <Link className="cap" to={`/discount/view/${_id}`}>{code}</Link>
        } else {
          return '-';
        }
      },
    },
    {
      title: "Min Order Amount ("+PRICE +")",
      dataIndex: "min_amount",
      key: "min_amount",
      render: (_, { min_amount }) => {
        return min_amount ? min_amount : '-';
      },
    },
    {
      title: "Amount ("+PRICE +")",
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return amount ? amount : '-';
      },
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (_, { percentage }) => {
        return percentage ? (percentage)+"%" : '-';
      },
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      key: "from_date",
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      key: "to_date",
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a> );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="Update Discount" color={"purple"} key={"updatediscount"}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i class="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            <Tooltip title="View Discount" color={"purple"} key={"viewdiscount"}>
              <Button title="View" onClick={(e) => view(record)}>
                <i class="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];


  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusDiscount + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: apiPath.listDiscount + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Discount Management"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By Title & Code"
                  />
                  <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add Discount</Button>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [fromDate, setFromDate] = useState();
  const [discountType, setDiscountType] = useState('flat');

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data, from_date: moment(data.from_date), to_date: moment(data.to_date) })
    setDiscountType(data.type);
  }, [data])

  const onCreate = (values) => {
    const { code, from_date, to_date, amount, percentage, type, min_amount } = values
    const payload = {}
    setLoading(true)
    payload.code = code;
    payload.from_date = moment(from_date).format('YYYY-MM-DD');
    payload.to_date = moment(to_date).format('YYYY-MM-DD');
    payload.type = discountType;
    payload.min_amount = min_amount;

    if(discountType == 'flat' || type == 'flat'){
      payload.amount = amount;
      payload.percentage = null;
    }else{
      payload.amount = null;
      payload.percentage = percentage;
    }

    request({
      url: `${data ? apiPath.addEditDiscount + "/" + data._id : apiPath.addEditDiscount}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  const handleTypeChange = (type) => {
    setDiscountType(type);
  }

  const handleFromData = (value) => {
    setFromDate(moment(value).format('YYYY-MM-DD'));
  }

  const discountTypeOption = [
    { value : 'flat', label : 'Flat' },
    { value : 'percentage', label : 'Percentage' }
  ]

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Discount' : 'Create a New Discount'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <Form.Item label="Code" name="code"
              rules={[
                { required: true, message: "Please enter the code!" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter Code"/>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label={'Min Order Amount ('+PRICE +')'} name="min_amount"
              rules={[
                { required: true, message: "Please enter the min order amount!" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={8} autoComplete="off" placeholder="Enter Min Order Amount"/>
            </Form.Item>
          </Col>
          
          <Col span={24} sm={12}>
            <Form.Item label="Type" name="type" required>
              <Select defaultValue="flat" options={discountTypeOption} onChange={handleTypeChange} name="type" placeholder="Select Type"/>
            </Form.Item>
          </Col>
          
          { discountType == 'flat' ? 
            <Col span={24} sm={12}>
              <Form.Item label={'Discount Amount ('+PRICE +')'} name="amount"
                rules={[
                  { required: true, message: "Please enter the discount amount!" },
                  { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
                ]}
              >
                <Input maxLength={8} autoComplete="off" placeholder="Enter Discount Amount"/>
              </Form.Item>
            </Col>
          :
            <Col span={24} sm={12}>
              <Form.Item label='Percentage' name="percentage"
                rules={[
                  { required: true, message: "Please enter the discount percentage!" },
                  { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
                  { pattern : new RegExp(/^([1-9][0-9]?|100)$/), message: "Percentage should be between 1-100" }
                ]}
              >
                <Input maxLength={3} autoComplete="off" placeholder="Enter Discount Percentage"/>
              </Form.Item>
            </Col>
          }
          
          <Col span={24} sm={12}>
            <Form.Item label="From Date" name="from_date"
              rules={[
                { required: true, message: "Please enter the from date!" },
              ]}
            >
               <DatePicker disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))} onChange={handleFromData} placeholder="From Date" />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item label="To Date" name="to_date"
              rules={[
                { required: true, message: "Please enter the to date!" },
              ]}
            >
              <DatePicker placeholder="To Date" disabledDate={(current) => current.isBefore(moment().subtract(1, "day")) || current.isBefore(fromDate)} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
