import {Row,Col,Card,Button,Input,Form,Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

function Edit() {

    const [form]                = Form.useForm();
    const { request }           = useRequest()
    const params                = useParams();
    const [loading, setLoading] = useState(false)
    const navigate              = useNavigate();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    const [arEditorState, setArEditorState] = useState(
        () => EditorState.createEmpty()
    );
    
    const fetchData = (id) => {
        request({
            url: apiPath.viewContent + "/" + id,
            method: 'GET',
            onSuccess: (data) => {
                setLoading(false);
                form.setFieldsValue(data.data);

                if(data.data.description){
                    const blocksFromHTML = convertFromHTML(data.data.description);
                    const content = ContentState.createFromBlockArray(blocksFromHTML);
                    const editorState = EditorState.createWithContent(content);
                    setEditorState(editorState);
                }

                if(data.data.ar_description){
                    const blocksFromHTMLAr = convertFromHTML(data.data.ar_description);
                    const contentAr = ContentState.createFromBlockArray(blocksFromHTMLAr);
                    const editorStateAr = EditorState.createWithContent(contentAr);
                    setArEditorState(editorStateAr);
                }

            },
            onError: (error) => {
                ShowToast(error, Severty.ERROR)
            }
        })
    }

    const OnUpdate = (values) => {
        const en_html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (en_html.trim() === '<p></p>') return ShowToast('Please Enter Description in English', Severty.ERROR)

        const ar_html = draftToHtml(convertToRaw(arEditorState.getCurrentContent()))
        if (ar_html.trim() === '<p></p>') return ShowToast('Please Enter Description in Arabic', Severty.ERROR)

        const { name, ar_name } = values
        const payload = {};
        payload.name = name;
        payload.description = en_html;
        payload.ar_description = ar_html;
        payload.ar_name = ar_name;
        setLoading(true)
        request({
            url: apiPath.addEditContent + "/" + params.id,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate('/content');
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    useEffect(() => {
      setLoading(true)
      fetchData(params.id)
    }, [])

    return (
        <Main>
            <Card title="Edit Content">
                {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                    <Form className="edit-page-wrap" form={form} onFinish={OnUpdate} autoComplete="off" layout="verticle" name="subscription_form">
                        <Row gutter={[24, 0]}>

                            <Col span={24} sm={12}>
                                <Form.Item label="Name (En)" name="name"
                                    rules={[
                                        { required: true, message: "Please Enter the name in english!" },
                                    ]}
                                >
                                <Input autoComplete="off" placeholder="Enter Name in English"/>
                                </Form.Item>
                            </Col>


                            <Col span={24} sm={12}>
                                <Form.Item label="Name (Ar)" name="ar_name"
                                    rules={[
                                        { required: true, message: "Please Enter the name in arabic!" },
                                    ]}
                                >
                                <Input autoComplete="off" placeholder="Enter Name in Arabic"/>
                                </Form.Item>
                            </Col>


                            <Col span={24} xxl={12}>
                                <Form.Item label="Description (En)" name="description"
                                rules={[
                                    { required: true, message: "Please Enter the description!" },
                                ]}>
                                <Editor
                                    editorState={editorState}
                                    placeholder="Enter Content Description in English"
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={setEditorState}
                                />
                                </Form.Item>
                            </Col>


                            <Col span={24} xxl={12}>
                                <Form.Item label="Description (Ar)" name="ar_description"
                                rules={[
                                    { required: true, message: "Please Enter the description!" },
                                ]}>
                                <Editor
                                    editorState={arEditorState}
                                    placeholder="Enter Content Description in Arabic"
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={setArEditorState}
                                />
                                </Form.Item>
                            </Col>
                        
                        </Row>

                        <Form.Item className="btn-row float-right mb-0">
                            <Link className="ant-btn ant-btn-default" to="/content/">Back</Link>
                            <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                }
            </Card>
        </Main >
    )
}
export default Edit;
