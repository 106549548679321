import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

const GoogleHeatMap = ({ google, location }) => {

  const mapRef = useRef(null);

  const [heatmap, setHeatmap] = useState()

  let heatmapData = [];

  useEffect(() => {
    const map   =   mapRef.current?.map;

    if(heatmap){
      heatmap.setMap(heatmap.getMap() ? null : map)
    }

    heatmapData = location.map((item, index) => (
      new google.maps.LatLng(item.latitude, item.longitude)
    ))

    const gradient = [
      "rgba(0, 255, 255, 0)",
      "rgba(0, 255, 255, 1)",
      "rgba(0, 191, 255, 1)",
      "rgba(0, 127, 255, 1)",
      "rgba(0, 63, 255, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 0, 223, 1)",
      "rgba(0, 0, 191, 1)",
      "rgba(0, 0, 159, 1)",
      "rgba(0, 0, 127, 1)",
      "rgba(63, 0, 91, 1)",
      "rgba(127, 0, 63, 1)",
      "rgba(191, 0, 31, 1)",
      "rgba(255, 0, 0, 1)",
    ];

    if (mapRef.current && google && google.maps) {
     
      const heatmap = new google.maps.visualization.HeatmapLayer({
        data          :   heatmapData,
        opacity       :   1,
        // gradient   :   gradient,
      });

      setHeatmap(heatmap)
      heatmap.setMap(map);
    }

  }, [google, location]);

  return (
    <Map mapTypeId={google.maps.MapTypeId.SATELLITE} google={google} zoom={7} initialCenter={{ lat: 26.922070, lng: 75.778885 }} ref={mapRef} />
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCnNf6kIq3DO1pSmHlP3qlehZgeaibm4oM',
  libraries: ['visualization'],
})(GoogleHeatMap);
