import { Row, Col, Card, Button, Input, Form, Upload, Radio, Select, message, Space, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import { PRICE } from "../../constants/conts";
import { Link } from "react-router-dom";

const S3_BUCKET = 'carwash-s3';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5FTZEKCY6QNUX77Q';
const SECRET_ACCESS_KEY = '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';

const config = {
    dirName: "product-service",
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

function Add() {

    const [form] = Form.useForm();
    const [file, setFile] = useState([]);
    const [vehicleMake, setVehicleMake] = useState([]);
    const [vehicleModel, setVehicleModel] = useState([]);
    const [vehicleType, setVehicleType] = useState([]);
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
    const [makeLoading, setMakeLoading] = useState(false);

    const navigate = useNavigate();
    const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

    const handleTypeChange = (value) => {
        const selected = vehicleType.find(item => item._id == value);
        if (selected) {
            setMakeLoading(true);
            //getVehicleModelList(selected.make_id)
            form.setFieldsValue({ vehicle_make: selected.make_id, vehicle_model: selected.model_id });
            setMakeLoading(false);
        }
    }

    const getVehicleTypeList = () => {
        request({
            url: apiPath.carTypeList,
            method: 'GET',
            onSuccess: (data) => {
                setVehicleType(data.data);
            },
            onError: (error) => {
                console.log(error)
                ShowToast(error, Severty.ERROR)
            }
        })
    };

    // const getVehicleMakeList = () => {
    //     request({
    //       url: apiPath.carMakeList,
    //       method: 'GET',
    //       onSuccess: (data) => {
    //         setVehicleMake(data.data);
    //       },
    //       onError: (error) => {
    //         ShowToast(error, Severty.ERROR)
    //       }
    //     })
    // };

    // const getVehicleModelList = (make_id) => {
    //     request({
    //       url: apiPath.carModelList + "/" + make_id,
    //       method: 'GET',
    //       onSuccess: (data) => {
    //         setVehicleModel(data.data);
    //       },
    //       onError: (error) => {
    //         ShowToast(error, Severty.ERROR)
    //       }
    //     })
    // };

    const handleImgChange = async (event) => {
        const { file } = event
        setFile([file])
        uploadFile(file, config)
            .then(data => {
                const fileData = {
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: data.location,
                    thumbUrl: data.location,
                }
                setFile([fileData])
            })
            .catch(err => console.error(err))

    };

    const beforeUpload = (file) => {
        if (FileType.includes(file.type)) {
        } else {
            message.error("File format is not correct")
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error(`Image must be smaller than 5 MB!`)
            return false
        }
        return true
    }

    const onCreate = (values) => {
        const { name, is_featured, time, price, vehicle_type, vehicle_make, vehicle_model, feature_list } = values
        const payload = {};
        payload.name = name;
        payload.time = time;
        payload.price = price;
        payload.vehicle_type = vehicle_type;
        // payload.vehicle_make = vehicle_make;
        // payload.vehicle_model = vehicle_model;
        payload.is_featured = is_featured ? 'yes' : 'no';
        payload.image = file.length > 0 ? file[0].url : '';
        payload.feature_list = feature_list && feature_list.map((item) => item.Features);
        setLoading(true)
        request({
            url: apiPath.addEditProductService,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate('/product-service');
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                setLoading(false)
                ShowToast(error.response.data.message, Severty.ERROR)
            },
        })
    };

    useEffect(() => {
        getVehicleTypeList();
        // getVehicleMakeList();
    }, [])


    return (
        <Main>
            <Card title="Add Product Service">
                <Form initialValues={{ feature_list: [{ Features: null }] }} Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="add_product_service_form">
                    <Row gutter={[24, 0]}>
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Name" name="name"
                                rules={[
                                    { required: true, message: "Please Enter the name!" },
                                    { max: 100, message: "Name should not contain more then 100 characters!" },
                                    { min: 2, message: "Name should contain atleast 1 characters!" },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Enter Name" />
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Vehicle Type" name="vehicle_type"
                                rules={[
                                    { required: true, message: "Please Enter the vehicle Type!" },
                                ]}
                            >
                                <Select onChange={handleTypeChange} placeholder="Select Vehicle Type">
                                    {vehicleType && vehicleType.length > 0 ? vehicleType.map((item, index) => (
                                        <option key={index} value={item._id}>
                                            <span className="cap"> {item.name} </span>
                                        </option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Vehicle Brand" name="vehicle_make"
                                rules={[
                                    { required: true, message: "Please Enter the vehicle brand!" },
                                ]}
                            >
                            <Select placeholder="Select Vehicle Brand" disabled>
                                {vehicleMake && vehicleMake && vehicleMake.length > 0 ? vehicleMake.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        <span className="cap"> {item.name} </span>
                                    </option>
                                )) : null}
                            </Select>
                            </Form.Item>
                        </Col>
                        
                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Vehicle Model" name="vehicle_model"
                                rules={[
                                    { required: true, message: "Please Enter the vehicle Model!" },
                                ]}
                            >
                            <Select placeholder="Select Vehicle Model" disabled>
                                {makeLoading ? <option disabled> Please Wait... Wa Are Fatching Models </option> :
                                    <>
                                        {vehicleModel && vehicleModel && vehicleModel.length > 0 ? vehicleModel.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                <span className="cap"> {item.name} </span>
                                            </option>
                                        )) : null}
                                    </>
                                }
                            </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label={`Price (` + PRICE + ')'} name="price"
                                rules={[
                                    { required: true, message: "Please Enter the price!" },
                                    { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" }
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Enter Price" />
                            </Form.Item>
                        </Col>


                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Time Required (Min)" name="time"
                                rules={[
                                    { required: true, message: "Please Enter the time!" },
                                    { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" }
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Enter Time Required" />
                            </Form.Item>
                        </Col>


                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Mark Service as Featured to be on Home Screen" name="is_featured">
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                            <Form.Item label="Image" name="image"
                                rules={[
                                    { required: true, message: "Please Enter the image!" },
                                ]}
                            >
                                <Upload
                                    accept="image/*"
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    customRequest={handleImgChange}
                                    onRemove={(e) => setFile([])}
                                    fileList={file}
                                >
                                    {file && file.length > 0 ? null : <Button icon={<UploadOutlined />}>Upload Image</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>

                        <Col span={24} sm={24} className="">

                            <Form.List name="feature_list" className="mt-2">
                                {(fields, { add, remove }, { form }) => (
                                    <>
                                        <Row gutter={[24, 0]}>
                                            {fields.map((field, index) => (
                                                <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                                    <div key={field.key}>
                                                        <Space key={field.key} align="baseline" className="gap-cls">
                                                            <div className="" style={{ display: "flex", gap: "8px" }}>
                                                                <Form.Item className="qty-cls w-100"
                                                                    {...field}
                                                                    name={[field.name, 'Features']}
                                                                    label="Features"
                                                                    rules={[{ required: true, message: 'Please enter features' }]}
                                                                >
                                                                    <Input autoComplete="off" placeholder={`Enter Features ` + (index + 1)} />
                                                                </Form.Item>
                                                                <div className="minus-wrap" style={{ marginTop: "22px" }}>
                                                                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ borderRadius: "8px", margin: "0" }} />
                                                                </div>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                </Col>
                                            ))}
                                            <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                                <Form.Item label="Add Features" className="mt-2">
                                                    <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </>
                                )}
                            </Form.List>
                        </Col>

                    </Row>

                    <Form.Item className="btn-row float-right mb-0">
                        <Link className="ant-btn ant-btn-default" to="/product-service/">Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Main >
    )
}
export default Add;
