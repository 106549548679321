import {Row,Col,Card,Button,Input,Form,Skeleton} from "antd";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, convertFromRaw ,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

function Edit() {

    const [form]                = Form.useForm();
    const { request }           = useRequest()
    const params                = useParams();
    const [loading, setLoading] = useState(false)
    const navigate              = useNavigate();
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );
    
    const fetchData = (id) => {
      
        request({
            url: apiPath.viewEmailTemplate + "/" + id,
            method: 'GET',
            onSuccess: (data) => {
            setLoading(false);
            form.setFieldsValue(data.data)
            const blocksFromHTML = convertFromHTML(data.data.description);
            const content = ContentState.createFromBlockArray(blocksFromHTML);
            const editorState = EditorState.createWithContent(content);
            setEditorState(editorState);
            },
            onError: (error) => {
            ShowToast(error, Severty.ERROR)
            }
        })
    }

    const OnUpdate = (values) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (html.trim() == '<p></p>') return ShowToast('Please Enter Description', Severty.ERROR)
        const { name,subject } = values
        const payload = {};
        payload.name = name;
        payload.description = html;
        payload.subject = subject;
        setLoading(true)
        request({
            url: apiPath.addEditEmailTemplate + "/" + params.id,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate('/email-template');
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    useEffect(() => {
      setLoading(true)
      fetchData(params.id)
    }, [])

    return (
        <Main>
            <Card title="Edit Email Template">
                {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                    <Form className="edit-page-wrap" form={form} onFinish={OnUpdate} autoComplete="off" layout="verticle" name="email_template_form">
                        <Row gutter={[24, 0]}>
                        <Col span={24} md={12}>
                            <Form.Item label="Title" name="title"
                                rules={[
                                    { required: true, message: "Please Enter the title!" },
                                ]}
                            >
                            <Input autoComplete="off" placeholder="Enter Title"/>
                            </Form.Item>
                        </Col>

                        <Col span={24} md={12}>
                            <Form.Item label="Subject" name="subject"
                                rules={[
                                    { required: true, message: "Please Enter the subject!" },
                                ]}
                            >
                            <Input autoComplete="off" placeholder="Enter Subject"/>
                            </Form.Item>
                        </Col>

                        <Col span={24} md={24}>
                            <Form.Item label="Description" name="description" rules={[
                                { required: true, message: "Please Enter the description!" },
                            ]}>
                            <Editor
                                editorState={editorState}
                                placeholder="Enter Email Template Description"
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={setEditorState}
                            />
                            </Form.Item>
                        </Col>
                        
                        </Row>

                        <Form.Item className="btn-row float-right">
                            <Link className="ant-btn ant-btn-default" to="/email-template/">Back</Link>
                            <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                }
            </Card>
        </Main >
    )
}
export default Edit;
