import React from "react";

function DeleteAccountSteps() {

  const fullScreenStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  };

  return (
    <div>
      <iframe src={"../../steps-to-delete-account.pdf"} style={fullScreenStyles} />
    </div>
  );
}

export default DeleteAccountSteps;
