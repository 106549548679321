import {Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Upload, Image, message, Tooltip} from "antd";
import apiPath from "../../constants/apiPath";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import ShowTotal from "../../components/ShowTotal";
import { uploadFile } from 'react-s3';
import moment from 'moment';
import notfound from "../../assets/images/not_found.png"

const Search = Input.Search;

const S3_BUCKET = 'carwash-s3';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5FTZEKCY6QNUX77Q';
const SECRET_ACCESS_KEY = '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';

const config = {
  dirName: "banner",
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}

function Index() {

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { confirm } = Modal;

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => <Image width={60} height={50} src={image ? image : notfound} />
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a> );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <div>
            <Tooltip title="Update Banner" color={"purple"} key={"update"}>
              <Button onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i class="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh])
  
  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusBanner + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: apiPath.listBanner + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? 10 : 10}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        const list = data.data.list.docs.map(item => ({ ...item, key: item._id }))
        setList(list)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Banner Management"
              extra={
                <Button onClick={(e) => setVisible(true)}>Add Banner</Button>
              }
            >
              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const FileType  = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]
  
  useEffect(() => {
    setImage([])
    if (!data) return
    form.setFieldsValue({ ...data })
    setFile([data.image])
    setImage([data.image])
  }, [data])
  
  
  const handleImgChange = async (event) => {
    const { file } = event
    setFile([file])
    uploadFile(file, config)
      .then(data => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: data.location,
          thumbUrl: data.location,
        }
        setFile([fileData])
      }
      )
      .catch(err => console.error(err))
  };

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
        message.error("File format is not correct")
        return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error(`Image must be smaller than 5 MB!`)
        return false
    }
    return true
  }

  const onCreate = (values) => {
    if (file.length <= 0) return ShowToast('Please select the banner Image ', Severty.ERROR)
    const payload = {}
    setLoading(true)
    payload.image = file.length > 0 ? file[0].url : '';
    request({
      url:  `${data ? apiPath.addEditBanner + "/" + data._id : apiPath.addEditBanner}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Edit Banner' : 'Create a New Banner'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical" >
        <Row>
        <Col span={24}>
          <Form.Item label="Upload Banner" name="image" rules={[ { required: true, message: "Please enter the banner image!" } ]} >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={beforeUpload}
              customRequest={handleImgChange}
              onRemove={(e) => setFile([])}
              fileList={file}
            >
              {file && file.length > 0 ? null : <Button icon={<UploadOutlined />}>Upload Banner</Button>}

            </Upload>
            </Form.Item>
            
            { image && image.length > 0 ? <div className="mt-3"> <Image width={150} src={image}></Image> </div> : null }
            
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
