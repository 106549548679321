import {Row,Col,Card,Table,Button,Modal,Input,Tag,Image,Tooltip} from "antd";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import useDebounce from "../../hooks/useDebounce";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ShowTotal from "../../components/ShowTotal";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import moment from 'moment';
import { Link } from "react-router-dom";
import notfound from "../../assets/images/not_found.png"
const Search = Input.Search;

function Index() {

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;
  const navigate = useNavigate();

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const view = (record) => {
    navigate(`/product-service/view/${record._id}`)
  }

  const edit = (record) => {
    navigate(`/product-service/update/${record._id}`)
  }

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => <Image width={50} height={50} src={image ? image : notfound} />
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      render: (_, {type}) => {
        return (
          type ? type.name : '-'
        )
      }
    },
    /* {
      title: "Brand",
      dataIndex: "vehicle_make",
      key: "vehicle_make",
      render: (_, {make}) => {
        return (
          make ? make.name : '-'
        )
      }
    },
    {
      title: "Model",
      dataIndex: "vehicle_model",
      key: "vehicle_model",
      render: (_, {model}) => {
        return (
          model ? model.name : '-'
        )
      }
    }, */
    {
      title: "Price (OMR)",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => {
        return price ? price.toFixed(2) : 0.00;
      }
    },
    {
      title: "Required Time (Min)",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Featured",
      dataIndex: "is_featured",
      key: "is_featured",
      render: (_, { is_featured }) => {
        let color = is_featured ? 'cyan' : 'red';
        return ( is_featured ? <Tag color={color} key={is_featured}>Yes</Tag> : <Tag color={color} key={is_featured}>No</Tag>);
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a> );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="Update Service" color={"purple"} key={"updateservice"}><Button title="Edit" onClick={() => { edit(record) }}><i class="fa fa-light fa-pen"></i></Button></Tooltip>
            <Tooltip title="View Service" color={"purple"} key={"viewservice"}><Button title="View Product Service" onClick={() => { view(record) }}><i class="fa fa-light fa-eye"></i></Button></Tooltip>
          </>
        );
      },
    },
  ];


  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusProductService + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: apiPath.listProductService + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    console.log(pagination, filters);
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Product Service Management"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                    value={searchText}
                    placeholder="Search By Name"
                  />
                  <Link className="ant-btn ant-btn-default" to="/product-service/add/">Add Product Service</Link>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>

            </Card>
          </Col>
        </Row>
      </div>
    </Main>
  );
}

export default Index;
