import { Row, Col, Card, Table, Button, Input, Tag, Tabs, DatePicker, Tooltip, Skeleton } from "antd";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import useDebounce from "../../hooks/useDebounce";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { PRICE } from "../../constants/conts";
import moment from 'moment';
import { AssignWasher } from "./AssignWasher";
import { ChangeStatus } from "./ChangeStatus";
import ShowTotal from "../../components/ShowTotal";
const Search = Input.Search;
const { TabPane } = Tabs;


function Subscription() {

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [statusRefresh, setStatusRefresh] = useState(false)
  const { RangePicker } = DatePicker;

  const assignWasher = (id) => {
    if(id){
      setVisible(true)
    }
  };
  
  const view = (record) => {
    navigate(`/subscription-order/view/${record._id}`)
  }

  const columns = [
    {
      title: "Order ID",
      dataIndex: "random_order_id",
      key: "random_order_id",
      render: (_, { random_order_id, _id }) => {
        if (random_order_id) {
          return <Link className="cap" to={`/subscription-order/view/${_id}`}>{random_order_id}</Link>;
        } else {
          return '-';
        }
      },
    },
    {
      title: "Customer Name & Number",
      render: (_, { user_id }) => {
        return (
          (user_id ? user_id.name : '') + " (" + (user_id ? (user_id.country_code ? "+" + user_id.country_code + "-" : "+968") + (user_id.mobile_number ? user_id.mobile_number : '') + ")" : '-')
        );
      },
    },
    {
      title: "Subscription Name",
      render: (_, { subscription }) => {
        return subscription && subscription.name ? subscription.name : '-';
      },
    },
    {
      title: "Amount ("+PRICE +")",
      dataIndex: "total",
      key: "total",
      render: (_, { total }) => {
        return total ? total : '-';
      },
    },
    {
      title: "Washer",
      dataIndex: "washer",
      key: "washer",
      render: (_, record ) => {
        return (
          <>
            {/* <a className="cap" onClick={(e) => { setSelected(record); assignWasher(record._id); }}>
              {record && record.washer ? record.washer.name : <Tag color="purple"> Add Washer</Tag>}
            </a> */}
            <div className="cap"> {record && record.washer ? <Link className="cap" to={`/washer/view/${record.washer._id}`}>{record.washer.name}</Link> : <Tag color="volcano">{"Not Assigned"}</Tag>} </div>
          </>
        );
      },
    },
    /* {
      title: "Status",
      key: "status",
      render: (_, { status, _id }) => {
        let color = status == "complete" ? "green" : status == "cancel" ? "red" : status == "accept" ? "cyan" : "yellow" ;
        return (
          <a><Tag onClick={(e) => !(status == "complete" || status == "cancel") ? changeStatus(_id) : null} color={color} key={status}>
            {
              status == "assign" ? "Assigned" : 
              status == "accept" ? "Accepted" : 
              status == "confirm" ? "Confirmed" : 
              status == "reach" ? "Reached" : 
              status == "in-progress" ? "In-Progress" : 
              status == "complete" ? "Completed" : 
              status == "cancel" ? "Cancelled" : 
              "Un-Assigned"
            }
          </Tag></a>
        );
      },
    }, */
    {
      title: "Ordered On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY, hh:mm A')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="View Order" color={"purple"} key={"vieworder"}>
              <Button onClick={(e) => view(record)}>
                <i class="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, statusRefresh, debouncedSearchText, startDate, endDate])


  const fetchData = (pagination, filters, orderStatus) => {
    setLoading(true)
    request({
      url: apiPath.listOrder + `?order_type=${'Subscription'}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&order_status=${orderStatus ? orderStatus : "all"}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if(e!=null){
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    }else{
      setStartDate()
      setEndDate()
    }
  };

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Subscription Orders"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                    value={searchText}
                    placeholder="Search By Order ID"
                  />
                  <RangePicker onChange={handleChangeDate} />
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>

            </Card>
          </Col>
        </Row>

        {visible && <AssignWasher show={visible} hide={() => { setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      </div>
    </Main>
  );
}

export default Subscription;