import { Row, Col, Card, Table, Button,Select, Input, Tag, Tooltip, DatePicker, Space } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { PRICE } from "../../constants/conts";
import * as XLSX from 'xlsx';
import ShowTotal from "../../components/ShowTotal";
import moment from 'moment';
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;
const Search = Input.Search;

function Index() {

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [serviceList, setServiceList]         = useState([]);
  const [subscriptionList, setSubscriptionList]     = useState([]);
  const [washerList, setWasherList]           = useState([]);
  const [serviceIds, setServiceIds]           = useState([]);
  const [washerId, setWasherId]               = useState();
  const [subscriptionIds, setSubscriptionIds] = useState([]);

  const navigate = useNavigate();

  const getServiceList = () => {
    request({
      url: apiPath.productServiceList,
      method: 'GET',
      onSuccess: (data) => {
        setServiceList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getSubscriptionList = () => {
    request({
      url: apiPath.subscriptionList,
      method: 'GET',
      onSuccess: (data) => {
        setSubscriptionList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getWasherList = () => {
    request({
      url: apiPath.washerList,
      method: 'GET',
      onSuccess: (data) => {
        setWasherList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const view = (record) => {
    navigate(`/transaction/view/${record._id}`)
  }

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Order Type",
      dataIndex: "type",
      filters: [
        {
          text: 'OnDemand',
          value: 'OnDemand',
        },
        {
          text: 'Subscription',
          value: 'Subscription',
        },
      ],
      render: (_, { type, subscription_id }) => {
        console.log(subscription_id, "subscription_id");
        if (type === 'OnDemand') {
          return <span className="cap">{type}</span>;
        } else if (type === 'Subscription') {
          if (subscription_id && subscription_id.name) {
            return <span className="cap">{type} - {subscription_id.name}</span>;
          } else {
            return <span className="cap">{type}</span>;
          }
        } else {
          return '-';
        }
      },
    },
    {
      title: "Washer Assign",
      dataIndex: "washer_id",
      key: "washer_id",
      render: (_, { washer_id }) => {
        if (washer_id) {
          return <Link className="cap" to={`/washer/view/${washer_id._id}`}>{washer_id.name}</Link>;
        } else {
          return '-';
        }
      },
    },
    {
      title: "Total Amount ("+PRICE +")",
      dataIndex: "total",
      key: "total",
      render: (_, { total }) => {
        return total ? total : '-';
      },
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (_, { payment_method }) => {
        return payment_method ? <span className="cap">{payment_method}</span> : '-';
      },
    },
    {
      title: "Payment Status",
      key: "payment_status",
      filters: [
        {
          text: 'Cod',
          value: 'Cod',
        },
        {
          text: 'Paid',
          value: 'paid',
        },
        {
          text: 'Cancel',
          value: 'cancel',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
      ],
      render: (_, { payment_status }) => {
        let color = payment_status === 'paid' ? 'green' : payment_status === 'cancel' ? 'red' : 'yellow';
        return (
          <Tag className="cap" color={color} key={payment_status}> {payment_status ? payment_status : 'Pending'} </Tag>
        );
      },
    },
    {
      title: "Transaction On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format('DD-MM-YYYY');
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="View Transaction" color={"purple"} key={"viewtransaction"}>
              <Button title="View" onClick={(e) => view(record)}>
                <i class="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
    getServiceList()
    getWasherList()
    getSubscriptionList()
  }, [refresh, debouncedSearchText, startDate, endDate, washerId, serviceIds, subscriptionIds])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.payment_status : null;
    const filterType   = filters ? filters.type : null;
    const payload = {}
    payload.payment_status = filterActive ? filterActive.join(',') : '';
    payload.type = filterType ? filterType.join(',') : '';
    payload.page = pagination ? pagination.current : 1;
    payload.limit = pagination ? pagination.pageSize : 10;
    payload.search = debouncedSearchText;
    payload.start_date = startDate ? startDate : "";
    payload.end_date = endDate ? endDate : "";
    payload.subscription_ids = subscriptionIds ? subscriptionIds : "";
    payload.service_ids = serviceIds ? serviceIds : "";
    payload.washer_id = washerId ? washerId : "";
    request({
      url: apiPath.listTransaction,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleReset = () => {
    setServiceIds([]);
    setWasherId();
    setSubscriptionIds([]);
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleService = (e) => {
    setServiceIds(e)
    setPagination({ current: 1 })
  };

  const handleSubscription = (e) => {
    setSubscriptionIds(e)
    setPagination({ current: 1 })
  };

  const handleWasher = (e) => {
    setWasherId(e)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if(e!=null){
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    }else{
      setStartDate()
      setEndDate()
    }
  };

  const handleExport = () => {
    const data = list.map(row => ({
      "Transaction ID": row.transaction_id,
      "Order ID": row.order_id && row.order_id.random_order_id ? row.order_id.random_order_id : '-',
      "Order Type": row.order_id && row.order_id.order_type ? row.order_id.order_type : '-',
      "Order Status": row.order_id && row.order_id.status ? row.order_id.status : '-',
      "Type": row.type,
      "Washer Name": row.washer_id && row.washer_id.name ? row.washer_id.name : '-',
      "Payment Status": row.payment_status,
      "Discount Code": row.discount_code ? row.discount_code : '-',
      "Total (OMR)": row.total,
      "Transaction On": moment(row.created_at).format("DD-MM-YYYY"),
    }));

    const workbook  = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transaction Data');
    XLSX.writeFile(workbook, `${moment().milliseconds() + 1000 * (moment().seconds() + 60 * 60)+ "-transactions"}.xlsx`);
  };

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Transaction Management"
              extra={
                <>
                  <Search size="large" onChange={onSearch} onPressEnter={onSearch} value={searchText} placeholder="Search By Transaction ID" />
                  <RangePicker onChange={handleChangeDate} />
                  <Button title="Export" onClick={handleExport}><i class="fas fa-download"></i>&nbsp;&nbsp;Export</Button>
                </>
              }
            >

              <Row gutter={[24,0]} >
                <Col xs={24} xl={8} sm={8} md={8} lg={8} className="mb-24">
                  <Select style={{ width: '100%' }} value={serviceIds} placeholder="Filter By Services (Multiple)" mode="multiple" name="service_id" onChange={handleService}>
                    {serviceList && serviceList && serviceList.length > 0 ? serviceList.map((item, index) => (
                    <option key={index} value={item._id}>
                        <span className="cap">{item.name}</span>
                    </option>
                    )) : null}
                  </Select> 
                </Col>
                  
                <Col xs={24} xl={8} sm={8} md={8} lg={8} className="mb-24">
                  <Select style={{ width: '100%' }} value={subscriptionIds} placeholder="Filter By Subscription (Multiple)" mode="multiple" name="subscription_id" onChange={handleSubscription}>
                    {subscriptionList && subscriptionList && subscriptionList.length > 0 ? subscriptionList.map((item, index) => (
                    <option key={index} value={item._id}>
                        <span className="cap">{item.name}</span>
                    </option>
                    )) : null}
                  </Select>
                </Col>
                
                <Col xs={24} xl={8} sm={8} md={8} lg={8} className="mb-24" style={{display:"flex", alignItems:"center", gap:"6px"}}>
                  <Select style={{ width: '80%' }} value={washerId} placeholder="Filter By Washer" name="washer_id" onChange={handleWasher}>
                    {washerList && washerList && washerList.length > 0 ? washerList.map((item, index) => (
                    <option key={index} value={item._id}>
                        <span className="cap">{item.name}</span>
                    </option>
                    )) : null}
                  </Select>
                  <Button style={{ width: 'auto' }} onClick={handleReset} title="Refresh"><i class="fas fa-sync-alt"></i></Button>
                </Col>
                
              </Row>

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Main>
  );
}

export default Index;