import {Row, Col, Card, Button, Input, Form, Select, Space, DatePicker} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { PRICE } from "../../constants/conts";
import { Link } from "react-router-dom";
import moment from 'moment';

function Add() {

    const [form]                = Form.useForm();
    const { request }           = useRequest()
    const [loading, setLoading] = useState(false)
    const navigate              = useNavigate();
    const [serviceList, setServiceList]     = useState([]);
    const [fromDate, setFromDate] = useState();

    const numbers = Array.from({ length: 80 }, (_, index) => index + 1);
    
    const getServiceList = () => {
        request({
          url: apiPath.productServiceList,
          method: 'GET',
          onSuccess: (data) => {
            setServiceList(data.data);
          },
          onError: (error) => {
            console.log(error)
            ShowToast(error, Severty.ERROR)
          }
        })
    };

    const onCreate = (values) => {

        const { name, from_date, to_date, price, discount, services } = values
        const payload = {}
        payload.discount = discount;
        payload.price = price;
        payload.name = name;
        payload.services = services;
        payload.from_date = moment(from_date).format('YYYY-MM-DD');
        payload.to_date = moment(to_date).format('YYYY-MM-DD');

        setLoading(true)
        
        request({
            url: apiPath.addEditSubscription,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate('/subscription');
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    
    const handleFromData = (value) => {
        setFromDate(moment(value).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        getServiceList()
    }, [])
    
    return (
        <Main>
            <Card title="Add Subscription">
                <Form initialValues={{services:[{service_id: null,no_of_time: null}]}} className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="subscription_form">
                    <Row gutter={[24, 0]}>
                        <Col span={24} md={12} xxl={8}>
                            <Form.Item label="Name" name="name"
                                rules={[
                                    { required: true, message: "Please Enter the name!" },
                                ]}
                            >
                            <Input autoComplete="off" placeholder="Enter Name"/>
                            </Form.Item>
                        </Col>

                        <Col span={24} md={12} xxl={8}>
                            <Form.Item label={`Original Price/Month (`+PRICE +')'} name="price"
                                rules={[
                                    { required: true, message: "Please Enter the price!" },
                                    { pattern: new RegExp(/^[0-9 ]*$/), message: "Only Numeric Characters Allowed" },
                                ]}
                            >
                            <Input maxLength={10} autoComplete="off" placeholder="Enter Original Price"/>
                            </Form.Item>
                        </Col>

                        <Col span={24} md={12} xxl={8}>
                            <Form.Item label={`Discount Price/Month (`+PRICE +')'} name="discount"
                                rules={[
                                    { required: true, message: "Please Enter the discount!" },
                                    { pattern: new RegExp(/^[0-9 ]*$/), message: "Only Numeric Characters Allowed" },
                                ]}
                            >
                            <Input maxLength={10} autoComplete="off" placeholder="Enter Discount Price"/>
                            </Form.Item>
                        </Col>

                        <Col span={24} md={12} xxl={8}>
                            <Form.Item label="From Date" name="from_date"
                                rules={[
                                    { required: true, message: "Please enter the from date!" },
                                ]}
                                >
                                <DatePicker style={{width : "100%", height: "42px", borderRadius: "6px"}} disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))} onChange={handleFromData} placeholder="From Date" />
                            </Form.Item>
                        </Col>

                        <Col span={24} md={12} xxl={8}>
                            <Form.Item  label="To Date" name="to_date"
                                rules={[
                                    { required: true, message: "Please enter the to date!" },
                                ]}
                                >
                                <DatePicker style={{width : "100%", height: "42px", borderRadius: "6px"}} placeholder="To Date" disabledDate={(current) => current.isBefore(moment().subtract(1, "day")) || current.isBefore(fromDate)} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.List name="services" className="mt-2">
                                {(fields, { add, remove }, { form }) => (
                                    <>
                                    <Row gutter={[16, 0]}>
                                        {fields.map((field, index) => (
                                            <Col span={24} xl={12} xxl={8}>
                                                <div key={field.key}>
                                                    <Space key={field.key} align="baseline" className="gap-cls">
                                                        <div className="serviceOuter" style={{display:"flex", gap:"16px"}}>
                                                            <Form.Item className="qty-cls w-100"
                                                                {...field}
                                                                name={[field.name, 'service_id']}
                                                                label="Services"
                                                                rules={[{ required: true, message: 'Please enter services' }]}
                                                            >
                                                            
                                                            <Select placeholder="Select Services">
                                                                {serviceList && serviceList && serviceList.length > 0 ? serviceList.map((item, index) => (
                                                                <option key={index} value={item._id}>
                                                                    {item.name}
                                                                </option>
                                                                )) : null}
                                                            </Select>

                                                            </Form.Item>
                                                            <div className="" style={{display:"flex", gap:"16px", width:"100%"}}>
                                                                <Form.Item className="qty-cls w-100"
                                                                    {...field}
                                                                    name={[field.name, 'no_of_time']}
                                                                    label="No. of Times"
                                                                    rules={[{ required: true, message: 'Please select no. of times' }]}
                                                                >
                                                                
                                                                <Select placeholder="Select No. of Times">
                                                                    {numbers.map((number) => (
                                                                        <option key={number} value={number}>{number}</option>
                                                                    ))}
                                                                </Select>

                                                                </Form.Item>
                                                                <div className="minus-wrap" style={{marginTop:"22px"}}>
                                                                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{borderRadius:"8px", margin:"0" }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </Col>
                                        ))}
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24} xl={12} xxl={8}>
                                                    <Form.Item className="" style={{}}>
                                                        <Button onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>


                                    </>
                                )}
                            </Form.List>
                        </Col>
                    

                        {/* <Col md={12}>
                            <Form.Item label="Select Services" name="services"
                                rules={[
                                    { required: true, message: "Please select services!" },
                                ]}
                            >
                                <Select placeholder="Select Services" mode="multiple">
                                    {serviceList && serviceList && serviceList.length > 0 ? serviceList.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                    )) : null}
                                </Select>
                            </Form.Item>
                        </Col> 

                        <Col md={12}>
                            <Form.Item label="No of Times" name="no_of_time"
                                rules={[
                                    { required: true, message: "Please Enter the No of Times!" },
                                    { pattern: new RegExp(/^[0-9 ]*$/), message: "Only Numeric Characters Allowed" }
                                ]}
                            >
                            <Input autoComplete="off" placeholder="Enter No of Times"/>
                            </Form.Item>
                        </Col> */}
                    </Row>

                    <Form.Item className="btn-row float-right mb-0">
                        <Link className="ant-btn ant-btn-default" to="/subscription/">Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Main >
    )
}
export default Add;
