import { Row, Col, Card, Button, Skeleton, Rate, Image, Tag, Table, Tooltip,Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import moment from 'moment';
import ShowTotal from "../../components/ShowTotal";
import { PRICE } from "../../constants/conts";
import { Badge } from 'antd';
import { AssignWasher } from "./AssignWasher";
import SubscriptionColumns from './SubscriptionColumns';
import ServiceColumns from './ServiceColumns';

function SubscriptionView() {

  const params                    =   useParams();
  const { request }               =   useRequest();
  const [list, setList]           =   useState({});
  const [orderSubscription, setOrderSubscription]   =   useState([]);
  const [history, setHisory]      =   useState([]);
  const [carColor, setCarColor]   =   useState('');
  const [loading, setLoading]     =   useState(false);
  const [visible, setVisible]     = useState(false);
  const [refresh, setRefresh] = useState(false);
  
  const [viewVisible, setViewVisible] = useState(false);
  const [viewRefresh, setViewRefresh] = useState(false);
  const [selected, setSelected] = useState();

  const ratingStatus = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewOrder + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data?.data?.getOrder);
        setHisory(data?.data?.getOrderStatus);
        setOrderSubscription(data?.data?.getOrderSubscription);
        setCarColor(data?.data?.getOrder?.car_id?.color_id?.color);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }


  const viewSubscription = (record) => {
    setViewVisible(true);
  }

  const columns = [
    {
      title: "Status",
      key: "status",
      render: (_, { status, _id }) => {
        let color = status === "complete" ? "green" : status === "cancel" ? "red" : status === "accept" ? "cyan" : "yellow" ;
        return (
          <a><Tag color={color} key={status}>
            {
              status === "assign" ? "Assigned" : 
              status === "accept" ? "Accepted" : 
              status === "confirm" ? "Confirmed" : 
              status === "reach" ? "Reached" : 
              status === "in-progress" ? "In-Progress" : 
              status === "complete" ? "Completed" : 
              status === "cancel" ? "Cancelled" : 
              "Un-Assigned"
            }
          </Tag></a>
        );
      },
    },
    {
      title: "Marked By",
      key: "mark_by",
      dataIndex: "mark_by",
      render: (_, { mark_by }) => {
        return (
          mark_by ? <span className="cap">{mark_by.name}</span> : '-'
        );
      },
    },
    {
      title: "Marked On",
      key: "mark_on",
      dataIndex: "mark_on",
      render: (_, { mark_on }) => {
        return (
          moment(mark_on).format('DD-MMM-YYYY, hh:mm A')
        );
      },
    },
  ];


  const assignWasher = (id) => {
    if(id){
      setVisible(true)
    }
  };

  const columnsOrder = [
    {
      title: "Order ID",
      dataIndex: "random_order_id",
      key: "random_order_id",
      render: (_, { random_order_id }) => {
        return random_order_id ? random_order_id : '-';
      },
    },
    {
      title: "Washer",
      dataIndex: "washer",
      key: "washer",
      render: (_, record) => {
        const isClickable = record.date_time_stamp < moment().format('YYYY-MM-DD hh:mm A');
    
        return (
          // <a className="cap" onClick={(e) => { setSelected(record); assignWasher(record._id); }}>
          //   {record && record.washer ? record.washer.name : "Add Washer"}
          // </a>
          <div>
            {isClickable ? (
              <span className="disabled-washer-row">
                {record && record.washer ? record.washer.name : "Add Washer"}
              </span>
            ) : (
              <a className="cap" onClick={(e) => { setSelected(record); assignWasher(record._id); }}>
                {record && record.washer ? record.washer.name : "Add Washer"}
              </a>
            )}
          </div>
        );
      },
    },    
    {
      title: "Service Date & Time",
      key: "date_time_stamp",
      dataIndex: "date_time_stamp",
      render: (_, { date_time_stamp }) => {
        return (
          moment(date_time_stamp).format('DD-MMM-YYYY, hh:mm A')
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (_, { status, _id }) => {
        let color = status === "complete" ? "green" : status === "cancel" ? "red" : status === "new" ? "magenta" : status === "in-progress" ? "blue" : "yellow" ;
        return (
          <a><Tag color={color} key={status}>
            {
              status === "assign" ? "Assigned" : 
              status === "accept" ? "Accepted" : 
              status === "confirm" ? "Confirmed" : 
              status === "reach" ? "Reached" : 
              status === "in-progress" ? "In-Progress" : 
              status === "complete" ? "Completed" : 
              status === "cancel" ? "Cancelled" : 
              "Un-Assigned"
            }
          </Tag></a>
        );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY, hh:mm A')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="View Order" color={"purple"} key={"vieworder"}>
              <Button onClick={(e) => { setSelected(record); viewSubscription(record._id); }}>
                <i class="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },

  ];

  useEffect(() => {
    fetchData(params.id)
  }, [refresh, viewRefresh])

  return (
    <Main>

      <Row gutter={16}>
        <Col span={24} sm={12}>
          <Card title="Order Details" bordered={false}>
            
            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Order ID:</h5>
                  <h6>{list.random_order_id}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Order Type:</h5>
                  <h6>{list.order_type}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer Name:</h5>
                  <h6 className="cap">{list && list.user_id && list.user_id.name ? <Link className="cap" to={`/customer/view/${list.user_id._id}`}>{list.user_id.name}</Link> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer Email:</h5>
                  <h6>{list && list.user_id && list.user_id.email ? list.user_id.email : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer Contact No.:</h5>
                  <h6>{list.user_id ? (list.user_id.country_code ? "+" + list.user_id.country_code + "-" : "+968") + (list.user_id.mobile_number ? list.user_id.mobile_number : '') : '-'}</h6>
                </div>

                {/* <div className="view-inner-cls">
                  <h5>Washer Name:</h5>
                  <h6>{list && list.washer_id && list.washer_id.name ? <a href={`/washer/view/${list.washer_id._id}`}>{list.washer_id.name}</a> : '-'}</h6>
                </div>
                
                <div className="view-inner-cls">
                  <h5>Washer Contact No.:</h5>
                  <h6>{list.washer_id ? (list.washer_id.country_code ? "+" + list.washer_id.country_code + "-" : "+968") + (list.washer_id.mobile_number ? list.washer_id.mobile_number : '') : '-'}</h6>
                </div> */}

                <div className="view-inner-cls">
                  <h5>Subscription Name:</h5>
                  <h6 className="cap">{list && list.subscription_id && list.subscription_id.name ? list.subscription_id.name : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY, hh:mm A') : '-'}</h6>
                </div>
              </div>
            }

          </Card>
        </Col>

        <Col span={24} sm={24} xl={12}>
          <Card title="Address" className="h-100">
            {loading ? [1,2].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Location:</h5>
                  <h6>
                    {list && list.city ? list.city : ''}
                    {list && list.state ? " - " + list.state : ''}
                    {list && list.country ? " - " + list.country : ''}
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Landmark:</h5>
                  <h6 className="cap">{list && list.landmark ? list.landmark : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>House No:</h5>
                  <h6 className="cap">{list && list.house_no ? list.house_no : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Address:</h5>
                  <h6 className="cap">{list && list.address ? list.address : '-'}</h6>
                </div>

              </div>
            }
          </Card>
        </Col>


        <Col span={24} sm={24} xl={12} className="mt-3">
          <Card title="Car Details" className="h-100" bordered={false}>
            {loading ? <Skeleton active /> :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Avatar:</h5>
                  <h6> { list?.car_id?.avatar_id?.image ? 
                        <Image width={50} height={50} src={list?.car_id?.avatar_id?.image}></Image> : <span className="text-danger">Image Not Available</span> }
                    </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Color:</h5>
                  <h6>
                    {
                      carColor ? <button className="carcolor" style={{ border: carColor ? carColor : '#ffffff00' , backgroundColor : carColor ? carColor : '#ffffff00' }}></button> : <span className="text-danger">Color Not Available</span>
                    }
                    </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Car :</h5>
                  <h6>
                    {(() => {
                      const carDetails = [
                          list?.car_id?.type_id?.name,
                          list?.car_id?.make_id?.name,
                          list?.car_id?.model_id?.name,
                          list?.car_id?.car_number
                      ].filter(Boolean).join(' - ');

                      return carDetails || <span className="text-danger">Car Details Not Found</span>;
                    })()}
                  </h6>
                </div>
              </div>
            }
          </Card>
        </Col>

        <Col span={24} xl={12} className="mt-3">
          <Card title="Payment Details" className="h-100">
            {loading ? [1,2].map(item => <Skeleton active key={item} />) :

              <div className="view-main-list">

                {/* <div className="view-inner-cls">
                  <h5>Discount Code:</h5>
                  <h6><a href={'/discount/view/'+list.discount_id ? list.discount_id : ''}>{list.discount_id ? list.discount_id.code : '-'}</a></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{'Discount Amount ('+PRICE +')'}:</h5>
                  <h6>{list.discount_amount ? list.discount_amount : '-'}</h6>
                </div> */}

                {/* <div className="view-inner-cls">
                  <h5>{'Sub Total ('+PRICE +')'}:</h5>
                  <h6>{list.sub_total}</h6>
                </div> */}

                <div className="view-inner-cls">
                  <h5>{'Total ('+PRICE +')'}:</h5>
                  <h6>{list.total}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{ list.payment_status ? list.payment_status : 'Pending'}</h6>
                </div>
              
                <div className="view-inner-cls">
                  <h5>Payment Mode:</h5>
                  <h6 className="cap">{ list.payment_method ? list.payment_method : 'Cod'}</h6>
                </div>

                { list.payment_status == 'paid' ?
                  <>
                    <div className="view-inner-cls">
                      <h5>Received On:</h5>
                      <h6>{list.payment_received_on ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Payment Session ID:</h5>
                      <h6>{list.payment_session_id ? list.payment_session_id : '-'}</h6>
                    </div>
                  </>
                : null }

                {/* <div className="view-inner-cls">
                  <h5>OTP Verified:</h5>
                  <h6>{ list.is_otp_verify ? <Tag color="green" > Verified </Tag> : <Tag color="red"> Not Verified </Tag> }</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>OTP Verified On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div> */}

              </div>
            }
          </Card>
        </Col>

        <Col span={24} sm={24}>
          <Card title="Additional Services" className="mt-3">
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>
                {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
                  <div className="mt-3 table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={ServiceColumns}
                      dataSource={list && list.service_id}
                      className="ant-border-space"
                    />
                  </div>
                }
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24} sm={24}>
          <Card title="Subscription Services" className="mt-3">
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>
                {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
                  <div className="mt-3 table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={SubscriptionColumns}
                      dataSource={list && list.subscription && list.subscription.services}
                      className="ant-border-space"
                    />
                  </div>
                }
              </Col>
            </Row>
          </Card>
        </Col>

        {list && list.order_type == 'Subscription' ?
          <>
            <Col span={24} xs={24} md={24}>
              <Card title={'Order Subscription ' + (list && list.subscription_id && list.subscription_id.name ? "(" + list.subscription_id.name + ")" : '')} className="cap mt-3">
                
                <h4 className="text-right mb-1">{orderSubscription && orderSubscription.length > 0 ? ShowTotal(orderSubscription.length) : ShowTotal(0)}</h4>
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={columnsOrder}
                    dataSource={orderSubscription}
                    pagination={{ defaultPageSize: 10, responsive: true, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50']}}
                    rowClassName={record => (record.date_time_stamp < moment().format('YYYY-MM-DD hh:mm A')) && "disabled-row"}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
            {visible && <AssignWasher show={visible} hide={() => { setVisible(false) }} data={selected} refresh={() => setViewRefresh(prev => !prev)} />}
          </>
        : null }
        <Col span={24} xs={24} md={24} className="mt-3">
          <div className="view-inner-cls float-right">
            <Link className="ant-btn ant-btn-default" to="/subscription-order/">Back</Link>
          </div>
        </Col>
      </Row>

      {/* <Card title="Order History" className="mt-3">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={history}
                  pagination={{ defaultPageSize: 10, responsive: true, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  className="ant-border-space"
                />
              </div>
            }
          </Col>
        </Row>
      </Card> */}
      

      {viewVisible && <ViewSubscription show={viewVisible} hide={() => { setSelected(); setViewVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

    </Main>
  );
}

const ViewSubscription = ({ show, hide, data, refresh }) => {

  const ratingStatus = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];

  useEffect(() => {
    if (!data) return
  }, [data])

  const subServiceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return (<span className="cap">{name}</span> );
      },
    },
    {
      title: "Features Include",
      key: "feature_list",
      dataIndex: "feature_list",
      render: (_, { feature_list }) => {
        if (feature_list && Array.isArray(feature_list)) {
          return (
            <ul>
              {feature_list.map((feature, index) => (
                <li className="cap" style={{marginLeft: "-20px"}} key={index}>{feature}</li>
              ))}
            </ul>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: "Remaining Service",
      key: "no_of_time",
      render: (_, { no_of_time }) => {
        return no_of_time ? no_of_time : 0;
      },
    },
    {
      title: "Current Status",
      key: "is_complete",
      render: (_, { is_complete, _id }) => {
        let color = is_complete === true ? "green" : "red";
        return (
          <a>
            <Tag color={color} key={is_complete}> { is_complete === true ? "Completed" : "Pending" } </Tag>
          </a>
        );
      },
    },
    {
      title: "Updated On",
      key: "complete_on",
      dataIndex: "complete_on",
      render: (_, { complete_on }) => {
        return (
          complete_on ? moment(complete_on).format('DD-MMM-YYYY, hh:mm A') : '-'
        );
      },
    },
  ];

  return (
    <Modal
      visible={show}
      width={1200}
      style={{ top: 20 }}
      bodyStyle={{ height:"100%", overflowY: 'auto', flexDirection:"column", maxHeight: 'calc(100vh - 200px)' }}
      title='View Order Details'
      onCancel={hide}
      footer={[<Button onClick={() => hide()}>Ok</Button>]}
    >
      <Row gutter={16}>
        <Col span={24} xs={24} md={24}>
          <div className="view-main-list">

            <div className="view-inner-cls">
              <h5>Washer:</h5>
              <h6>
                {data && data.washer && data.washer.name ? <Link className="cap" to={`/washer/view/${data.washer._id}`}>{data.washer.name}</Link> : '-'}
                &nbsp;
                ({data.washer ? (data.washer.country_code ? "+" + data.washer.country_code + "-" : "+968") + (data.washer.mobile_number ? data.washer.mobile_number : '') : '-'})
              </h6>
            </div>
          
            <div className="view-inner-cls">
              <h5>Service Date & Time:</h5>
              <h6>{data.date_time_stamp ? moment(data.date_time_stamp).format('DD-MMM-YYYY, hh:mm A') : '-'}</h6>
            </div>
            
            <div className="view-inner-cls">
              <h5>Status:</h5>
              <h6>{
                data.status === 'complete' ? 
                <Badge status="success" text="Completed" /> :
                data.status === 'cancel' ? <Badge status="error" text="Cancelled" /> :
                data.status === 'in-progress' ? <Badge status="cyan" text="In-Progress" /> 
                : <Badge status="yellow" text="Un-Assigned Order" />
                }
                </h6>
            </div>

            <div className="view-inner-cls">
              <h5>Rating Received:</h5>
              <h6>{data.is_rate ? "Yes" : "No"}</h6>
            </div>

            {data && data.is_rate ?
              <>
                <div className="view-inner-cls">
                  <h5>Rating:</h5>
                  <h6> <Rate tooltips={ratingStatus} value={data.rate} /></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Review:</h5>
                  <h6>{data && data.review ? data.review : '-'}</h6>
                </div>
              </>
            : null }

          </div>

          {/* <div className="mt-3 table-responsive customPagination">
            <h4 className="theme-color">Additional Service</h4>
            <Card>
              <Table
                columns={ServiceColumns}
                dataSource={data && data.service_id && data.service_id}
                pagination={{ defaultPageSize: 5, responsive: true}}
                className="ant-border-space"
              />
            </Card>
          </div> */}

          <div className="mt-3 table-responsive customPagination">
            <h4 className="theme-color">Subscription Service</h4>
            <Card>
              <Table
                columns={SubscriptionColumns}
                dataSource={data && data.subscription && data.subscription.services}
                pagination={{ defaultPageSize: 5, responsive: true }}
                className="ant-border-space"
              />
            </Card>
          </div>

        </Col>
      </Row>
      
    </Modal>
  )
}

export default SubscriptionView;