import { Row, Col, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";
import apiPath from "../constants/apiPath";

function StaticPage() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (slug) => {
    request({
      url: apiPath.viewStaticPage + "/" + slug,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.slug)
  }, [])

  return (
  
    <div className="container d-flex justify-content-center align-items-center">
      <Row gutter={16}>
        <Col span={24} md={12}>
          {loading ? [1,2,3].map(item => <Skeleton active key={item} />) : (
            <h6 className="card-title">{list.description ? <p dangerouslySetInnerHTML={{__html: list.description}}></p> : "-"}</h6>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default StaticPage;
