import {Row,Col,Card,Button,Input,Form} from "antd";
import React, { useState } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, convertFromRaw ,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Link } from "react-router-dom";

function Add() {

    const [form]                = Form.useForm();
    const { request }           = useRequest()
    const [loading, setLoading] = useState(false)
    const navigate              = useNavigate();
    
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
    );

    const [arEditorState, setArEditorState] = useState(
        () => EditorState.createEmpty()
    );
    
    const onCreate = (values) => {

        if (!values.description) return ShowToast('Please enter description in english', Severty.ERROR)
        if (!values.ar_description) return ShowToast('Please enter description in arabic', Severty.ERROR)

        setLoading(true)
        const { name, ar_name } = values
        const payload   =   {};
        const en_html   =   draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const ar_html   =   draftToHtml(convertToRaw(arEditorState.getCurrentContent()))
        payload.name    =   name;
        payload.ar_name = ar_name;
        payload.description     = en_html;
        payload.ar_description  = ar_html;
        request({
            url: apiPath.addEditContent,
            method: 'POST',
            data: payload,
            onSuccess: (data) => {
                setLoading(false)
                if (data.status) {
                    ShowToast(data.message, Severty.SUCCESS)
                    navigate('/content');
                } else {
                    ShowToast(data.message, Severty.ERROR)
                }
            },
            onError: (error) => {
                ShowToast(error.response.data.message, Severty.ERROR)
                setLoading(false)
            },
        })
    };
    
    return (
        <Main>
            <Card title="Add Content">
                <Form Form className="edit-page-wrap" form={form} onFinish={onCreate} autoComplete="off" layout="verticle" name="content_form">
                    <Row gutter={[24, 0]}>

                        <Col span={24} sm={12}>
                            <Form.Item label="Name (En)" name="name"
                                rules={[
                                    { required: true, message: "Please Enter the name in english!" },
                                ]}
                            >
                            <Input autoComplete="off"  placeholder="Enter Name in English"/>
                            </Form.Item>
                        </Col>


                        <Col span={24} sm={12}>
                            <Form.Item label="Name (Ar)" name="ar_name"
                                rules={[
                                    { required: true, message: "Please Enter the name in arabic!" },
                                ]}
                            >
                            <Input autoComplete="off" placeholder="Enter Name in Arabic"/>
                            </Form.Item>
                        </Col>


                        <Col span={24} xxl={12}>
                            <Form.Item label="Description (En)" name="description"
                            rules={[
                                { required: true, message: "Please Enter the description!" },
                            ]}>
                            <Editor
                                editorState={editorState}
                                placeholder="Enter Content Description in English"
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={setEditorState}
                            />
                            </Form.Item>
                        </Col>


                        <Col span={24} xxl={12}>
                            <Form.Item label="Description (Ar)" name="ar_description"
                            rules={[
                                { required: true, message: "Please Enter the description!" },
                            ]}>
                            <Editor
                                editorState={arEditorState}
                                placeholder="Enter Content Description in Arabic"
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={setArEditorState}
                            />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className="btn-row float-right">
                        <Link className="ant-btn ant-btn-default" to="/content/">Back</Link>
                        <Button type="primary" loading={loading} htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Main >
    )
}
export default Add;
