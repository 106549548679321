import { useState, useEffect, useContext } from "react";
import { Row, message, Col, Breadcrumb, Tooltip, Dropdown, Button, Card, Input, Image, Form, Modal, Upload, TextArea } from "antd";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
import { KeyOutlined, LogoutOutlined, QuestionCircleFilled, UserOutlined, SettingOutlined } from "@ant-design/icons";
import apiPath from "../../constants/apiPath";
import { ShowToast, Severty } from "../../helper/toast";
import { NavLink, Link } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import notfound from "../../assets/images/not_found.png"
import avatarPlaceholder from "../../assets/images/defaults/avatarPlaceholder.png";

import editIcon from "../../assets/images/icon/editIcon.png"

import notification from "../../assets/images/icon/notification.svg";

import Delete from "../../assets/images/delete.svg";
import moment from "moment";

const S3_BUCKET = 'carwash-s3';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5FTZEKCY6QNUX77Q';
const SECRET_ACCESS_KEY = '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';

const config = { 
  dirName: "profile",
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];
function Header({name,onPress}) {
  
  useEffect(() => window.scrollTo(0, 0));
  const [visible, setVisible] = useState(false);

  const deleteNotification = (value, type) => {
    let url;
    
    if(value && type === 'single'){
      url   =   apiPath.deleteNotification + '/' + value._id;
    }else{
      url   =   apiPath.deleteNotification;
    }

    request({
      url: url,
      method: 'GET',
      onSuccess: (data) => {
        setNotificationList(data.data.list);
        setNotificationCount(data.data.count);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
    })
  }

  const { confirm }   =   Modal;
  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [profileVisible, setProfileVisible] = useState(false);

  const [appSetting, setAppSetting] = useState({});
  const [appSettingSelected, setAppSettingSelected] = useState();
  const [appSettingVisible, setAppSettingVisible] = useState(false);

  const [setting, setEditSetting] = useState({});
  const [editSettingSelected, setEditSettingSelected] = useState();
  const [editSettingVisible, setEditSettingVisible] = useState(false);

  const { request } = useRequest()
  const [refresh, setRefresh] = useState(false)
  const { logout } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const items = [
    {
      label: 'Edit Profile',
      key: '1',
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: 'Change Password',
      key: '2',
      icon: <KeyOutlined />,
      danger: true,
    },
    {
      label: 'App Setting',
      key: '3',
      icon: <SettingOutlined />,
      danger: true,
    },
    {
      label: 'Other Setting',
      key: '4',
      icon: <SettingOutlined />,
      danger: true,
    },
    {
      label: 'Logout',
      key: '5',
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const notificationItems = [
    {
      label: (
        <div className="notification_top">
          <div className="notification-head">
            <h5>Notifications</h5>

            {notificationList && notificationList.length > 0 ?  
              <a onClick={(e) => deleteNotification(null,'multiple')}> Clear All </a>
            : null }

          </div>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="notification-main-wrap">
          {notificationList && notificationList.length > 0 ? notificationList.map((item, index) => 
            <div className="notification-inner">
              <div className="notification-img"> <img src={notification} /> </div>
              <div className="notification-cont">
                <div className="notification-left">
                  <h4> {item.title} </h4>
                  <p> {item.description} </p>
                  <small> { item.created_at ? moment(item.created_at).format('DD-MM-YYYY') : ''} </small>
                </div>
                <div className="notification-right">
                  <a onClick={(e) => deleteNotification(item,'single')}>
                    <img src={Delete} />
                  </a>
                </div>
              </div>
            </div>
          ) : 'No Notification Found'}
        </div>
      ),
      key: "1",
    },

    /* { 
      label: 
        <div className="notification_bottom">
          <div className="notification-bot"> <Link to="#">See All</Link> </div>
        </div>,
      key: '0',
    } */
  ];

  const handleMenuClick = (e) => {
    if (e.key == 1) {
      setProfileVisible(true)
    }
    if (e.key == 2) {
      setVisible(true)
    }
    if (e.key == 3) {
      setAppSettingVisible(true)
    }
    if (e.key == 4) {
      setEditSettingVisible(true)
    }
    if (e.key == 5) {
      showDeleteConfirm()
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: 'Logout',
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: 'Cancel',
        content: <Button >Are you sure you want to logout ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values
    const payload = {}
    if (!old_password || !new_password) return ShowToast('Please enter password ', Severty.ERROR)
    setLoading(true)
    payload.new_password = new_password
    payload.old_password = old_password
    request({
      url: apiPath.changePassword,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          setVisible(false)
          logout()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    request({
      url: apiPath.profile,
      method: 'GET',
      onSuccess: (data) => {
        setProfile(data.data)
        setSelected(data.data)
      },
    })
    request({
      url: apiPath.listNotification,
      method: 'GET',
      onSuccess: (data) => {
        setNotificationList(data.data.list);
        setNotificationCount(data.data.count);
      },
    })
    request({
      url: apiPath.getAppSetting,
      method: 'GET',
      onSuccess: (data) => {
        setAppSetting(data.data)
        setAppSettingSelected(data.data)
      },
    })
    request({
      url: apiPath.getSetting,
      method: 'GET',
      onSuccess: (data) => {
        setEditSetting(data.data)
        setEditSettingSelected(data.data)
      },
    })
  }, [refresh])

  const parts = name.split("/");


  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} sm={12} md={12} className="breadcrumbMain">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Dashboard</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="cap">
              {(parts[0] ? parts[0] : "") + (parts[1] ? " / " + parts[1] : "")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col span={24} sm={24} md={12} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <div className="profileDropdownMain">
            <Dropdown menu={menuProps}>
              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open shadow-none">
                <div className="userName">
                  {profile ? profile.name : "Administrator"}
                </div>
                <div className="userEmail">
                  {profile ? profile.email : "admin@noreply.com"}{" "}
                </div>
              </Button>
            </Dropdown>

            <Image src={profile.image ?? avatarPlaceholder} />
          </div>

          <div className="notificationDropdownMain">
            <Dropdown menu={{ items: notificationItems }} trigger={["click"]}>
              <button
                onClick={(e) => e.preventDefault()}
                className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open notificationBtn"
              >
                <img src={notification} alt="notification" />{" "}
                <div className="notiFicationBadge">
                  {notificationCount ? notificationCount : 0}
                </div>
              </button>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {profileVisible && (
        <EditProfile
          show={profileVisible}
          hide={() => {
            setProfileVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {appSettingVisible && (
        <AppSetting
          show={appSettingVisible}
          hide={() => {
            setAppSettingVisible(false);
          }}
          data={appSettingSelected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {editSettingVisible && (
        <EditSetting
          show={editSettingVisible}
          hide={() => {
            setEditSettingVisible(false);
          }}
          data={editSettingSelected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      <Modal
        open={visible}
        title="Change password"
        okText="Ok"
        onCancel={closeModal}
        onOk={handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Old Password"
            name="old_password"
            hasFeedback
            rules={[
              { required: true, message: "Please enter the old password!" },
            ]}
          >
            <Input.Password placeholder="Enter Old Password" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            hasFeedback
            rules={[
              { required: true, message: "Please enter the new password!" },
              {
                pattern: new RegExp(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                ),
                message:
                  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
              },
            ]}
          >
            <Input.Password placeholder="Enter New Password" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_new_password"
            dependencies={["new_password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please enter the confirm password!" },
              {
                pattern: new RegExp(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                ),
                message:
                  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Confirm password & password does not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter Confirm Password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const EditProfile = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
        message.error("File format is not correct")
        return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error(`Image must be smaller than 5 MB!`)
        return false
    }
    return true
  }


  const handleChange = async (event) => {
    const { file } = event
    setFile([file])
    uploadFile(file, config)
      .then(data => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: data.location,
          thumbUrl: data.location,
        }
        setFile([fileData])
        console.log(data)
      }
      )
      .catch(err => console.error(err))
  };


  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
    setImage([data.image])
  }, [data])


  const onEditProfile = (values) => {
    const { email, name } = values
    const payload = {}
    setLoading(true)
    payload.email = email
    payload.name = name
    payload.image = file.length > 0 ? file[0].url : null
    request({
      url: apiPath.updateProfile,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      open={show}
      title={`${data ? 'Edit Profile' : 'Edit Profile'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onEditProfile} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label="Profile Image" name="image" style={{position:"relative"}} rules={[ { required: true, message: "Please enter the profile image!" } ]} >
              
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleChange}
                onRemove={(e) => setFile([])}
                fileList={file}
              >

              {file && file.length > 0 ? null : 
                <Button className="imgUpload" style={{position:"absolute", bottom:"0", left:"52px", zIndex:"1", padding:"2px"}} ><img className="w-100" src={editIcon}/></Button>
              }

              </Upload>
                <div className="userProfileOuter" style={{marginTop:"10px"}}>
                <Image style={{width:"100%", height:"100%", objectFit:"cover", objectPosition:"top center"}} src={image ? image : notfound}></Image> 
              </div>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Name" name="name"
              rules={[
                { required: true, message: "Please enter the name!" },
                { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "Only Alphabetic Characters Allowed" }
              ]}
            >
              <Input placeholder="Enter Name"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email Address" name="email"
              rules={
                [
                  {
                    type: 'email', message: 'The input is not valid E-mail!',
                  },
                  { required: true, message: "Please enter the email!" }
                ]}
            >
              <Input placeholder="Enter Email Address" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const EditSetting = ({ show, hide, data, refresh }) => {

  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
  }, [data])

  const onEditSetting = (values) => {
    const payload = { ... values }
    setLoading(true)
    request({
      url: apiPath.updateSetting,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      open={show}
      title={`${data ? 'Other Setting' : 'Other Setting'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onEditSetting} layout="vertical">
        <Row>

          <Col span={24}>
            <Form.Item label="Radius (KM)" name="radius"
              rules={[
                { required: true, message: "Please enter the radius!" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={12} placeholder="Please Enter Radius"/>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Tariff Charge (OMR)" name="tariff_charge"
              rules={[
                { required: true, message: "Please enter the tariff!" },
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={12} placeholder="Please Enter Tariff Charge (OMR)"/>
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  )
}

export const AppSetting = ({ show, hide, data, refresh }) => {
  const [form]                = Form.useForm();
  const { request }           = useRequest()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data });
  }, [data])


  const onAppSetting = (values) => {
    setLoading(true)
    request({
      url: apiPath.updateAppSetting,
      method: 'POST',
      data: values,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      width={1200}
      open={show}
      title={`${data ? 'Update App Setting' : ''}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      
      <Form id="create" form={form} onFinish={onAppSetting} layout="vertical">
        <Row>

          <Col span={24} md={12}>
            <Card title="Android Details">
              <Col span={24}>
                <Form.Item label="App Store URL" name="app_store_url"
                  rules={[
                    { required: true, message: "Please enter the app store URL!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Version" name="android_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Share Content" name="android_share_content"
                  rules={[
                    { required: true, message: "Please enter the share content!" },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share Content"
                  />

                </Form.Item>
              </Col>
            </Card>
          </Col>

         
          <Col span={24} md={12}>
            <Card title="IOS Details">

              <Col span={24}>
                <Form.Item label="Play Store URL" name="play_store_url"
                  rules={[
                    { required: true, message: "Please enter the play store URL!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24} className="mt-3">
                <Form.Item label="Version" name="ios_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24} className="mt-3">
                <Form.Item label="Share Content" name="ios_share_content"
                  rules={[
                    { required: true, message: "Please enter the share content!" },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>

        </Row>

      </Form>
    </Modal>
  )
}

export default Header;
