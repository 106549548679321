const { REACT_APP_ENV_MODE } = process.env;

let appMode = REACT_APP_ENV_MODE;
let ASSET_URL = "https://carwash-s3.s3.amazonaws.com";
let URL;

if (appMode === "development") {
  // URL = "http://localhost:5550/api/";
  // URL = 'http://16.16.48.15:5550/api/' - Lamsapro.app IP Address
  URL = 'https://lamsapro.app/api/'
} else {
  // URL = "http://localhost:5550/api/";
  // URL = 'http://16.16.48.15:5550/api/'
  URL = 'https://lamsapro.app/api/'
}

let pathObj = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/auth/dashboard",

  customerList: "admin/customer/list",
  customerShow: "admin/customer/show",

  listOrder: "admin/order/list",
  viewOrder: "admin/order/view",
  statusOrder: "admin/order/status",
  assignWasher: "admin/order/assign-washer",
  orderServices: "admin/order/order-services",

  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",

  listCashEntry: "admin/cash-entry/list",
  viewCashEntry: "admin/cash-entry/view",
  addEditCashEntry: "admin/cash-entry/add-edit",

  listNotification: "admin/notification/list",
  deleteNotification: "admin/notification/delete",
  viewedNotification: "admin/notification/view",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  getSetting: "admin/auth/get-setting",
  updateSetting: "admin/auth/update-setting",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  updatePaymentOrder: "app/customer/order/payment-status",
  paymentOrderView: "app/customer/order/payment-view",

  // Washer APIs
  listWasher: "admin/washer/list",
  addEditWasher: "admin/washer/add-edit",
  statusWasher: "admin/washer/status",
  viewWasher: "admin/washer/view",

  // Discount APIs
  listDiscount: "admin/discount/list",
  addEditDiscount: "admin/discount/add-edit",
  statusDiscount: "admin/discount/status",
  viewDiscount: "admin/discount/view",

  // Customer APIs
  listCustomer: "admin/customer/list",
  addEditCustomer: "admin/customer/add-edit",
  statusCustomer: "admin/customer/status",
  viewCustomer: "admin/customer/view",

  // Start Car Routes APIs
  listCarType: "admin/car/type",
  addEditCarType: "admin/car/add-edit-type",
  statusCarType: "admin/car/status-type",

  listCarBrand: "admin/car/make",
  addEditCarBrand: "admin/car/add-edit-make",
  statusCarBrand: "admin/car/status-make",
  importBrand: "admin/car/import-make",

  listCarModel: "admin/car/model",
  addEditCarModel: "admin/car/add-edit-model",
  statusCarModel: "admin/car/status-model",
  importCarModel: "admin/car/import-model",

  listCarAvatar: "admin/car/avatar",
  addEditCarAvatar: "admin/car/add-edit-avatar",
  statusCarAvatar: "admin/car/status-avatar",

  listCarColor: "admin/car/color",
  addEditCarColor: "admin/car/add-edit-color",
  statusCarColor: "admin/car/status-color",
  // End Car Routes APIs

  listCar: "admin/car/list",
  addEditCar: "admin/car/add-edit",
  statusCar: "admin/car/status",
  viewCar: "admin/car/view",

  // Subscription APIs
  listSubscription: "admin/subscription/list",
  addEditSubscription: "admin/subscription/add-edit",
  statusSubscription: "admin/subscription/status",
  viewSubscription: "admin/subscription/view",
  editSubscription: "admin/subscription/edit",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Banner APIs
  listBanner: "admin/banner/list",
  addEditBanner: "admin/banner/add-edit",
  statusBanner: "admin/banner/status",
  viewBanner: "admin/banner/view",

  // Content APIs
  listContent: "admin/content/list",
  addEditContent: "admin/content/add-edit",
  statusContent: "admin/content/status",
  viewContent: "admin/content/view",

  // ProductService APIs
  listProductService: "admin/product-service/list",
  addEditProductService: "admin/product-service/add-edit",
  statusProductService: "admin/product-service/status",
  viewProductService: "admin/product-service/view",
  editProductService: "admin/product-service/edit",

  // Common Routes
  carMakeList: "common/make-list",
  carModelList: "common/model-list",
  carTypeList: "common/type-list",
  orderList: "common/order-list",
  washerList: "common/washer-list",
  activeWasherList: "common/active-washer-list",
  customerList: "common/customer-list",
  subscriptionList: "common/subscriptions",
  productServiceList: "common/product-service-list",
  getAppSetting: "common/app-setting",

  viewStaticPage: "common/static-page",
};
export default pathObj;
