import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from "firebase/database";


const config = {
  apiKey: "AIzaSyDOVi5bbIm23JCDNvimmjg3bXWpDagxMg4",
  authDomain: "lamsa-car-user.firebaseapp.com",
  databaseURL: "https://lamsa-car-user-default-rtdb.firebaseio.com",
  projectId: "lamsa-car-user",
  storageBucket: "lamsa-car-user.appspot.com",
  messagingSenderId: "496718714668",
  appId: "1:496718714668:web:5d9bcd2340d775791d7e89",
  measurementId: "G-J4J31XVEYF"
}


const firebase = initializeApp(config);
const db = getFirestore(firebase);
const realDb = getDatabase(firebase);

export { db, firebase, realDb }