import { PRICE } from "../../constants/conts";
import { Image, Tag } from "antd";
import notfound from "../../assets/images/not_found.png"
import moment from 'moment';

const ServiceColumns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (_, { image, name }) => {
      return (
        <>
          <Image className="image-index-radius" src={image ? image : notfound} /> 
          <span className="cap" style={{ marginLeft: 12 }}>{name}</span>
        </>
      );
    },
  },
  {
    title: "Price ("+PRICE +")",
    key: "price",
    dataIndex: "price",
    render: (_, { price }) => {
      return price ? price : '-';
    },
  },
  {
    title: "Features Include",
    key: "feature_list",
    dataIndex: "feature_list",
    render: (_, { feature_list }) => {
      if (feature_list && Array.isArray(feature_list)) {
        return (
          <ul>
            {feature_list.map((feature, index) => (
              <li className="cap" style={{marginLeft: "-20px"}} key={index}>{feature}</li>
            ))}
          </ul>
        );
      } else {
        return '-';
      }
    },
  },
  {
    title: "Current Status",
    key: "is_complete",
    render: (_, { is_complete, _id }) => {
      let color = is_complete === true ? "green" : "red";
      return (
        <a>
          <Tag color={color} key={is_complete}> { is_complete === true ? "Completed" : "Pending" } </Tag>
        </a>
      );
    },
  },
  {
    title: "Updated On",
    key: "complete_on",
    dataIndex: "complete_on",
    render: (_, { complete_on }) => {
      return (
        complete_on ? moment(complete_on).format('DD-MMM-YYYY, hh:mm A') : '-'
      );
    },
  },
];

export default ServiceColumns;