import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography, Radio, Skeleton, Table, Tooltip, Button, Tag } from "antd";
import { Link } from 'react-router-dom';
import Main from "../../components/layout/Main";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { Bar } from "react-chartjs-2";
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js";
import { useNavigate } from "react-router";
import { PRICE } from "../../constants/conts";
import GoogleHeatMap from './GoogleHeatMap';
import apiPath from "../../constants/apiPath";
import useFirebase from "../../hooks/useFirebase";
import { realDb } from "../../config/firebase";

import GoogleMap from "../../components/GoogleMap";
function Home() {

  const navigate = useNavigate();
  const { Title, Text } = Typography;
  const [dashboard, setDashboard] = useState({
    customerCount: 0,
    washerCount: 0,
  })
  ChartJS.register(CategoryScale, LinearScale, BarElement);
  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const [barData, setBarData] = useState(false);
  const [washerLatLngLoading, setWasherLatLngLoading] = useState(true);
  const [customerGraphData, setGraphCustomerData] = useState({});
  const [washerGraphData, setGraphWasherData] = useState({});
  const [bookingGraphData, setGraphBookingData] = useState({});

  const [mostSoldServiceList, setMostSoldServiceList] = useState([])
  const [leastSoldServiceList, setLeastSoldServiceList] = useState([])
  const [userSubscriptionExpiringList, setUserSubscriptionExpiringList] = useState([])
  const [washerLocation, setWasherLocation] = useState([])
  const [availableWasherLocation, setWasherAvailableLocation] = useState([])
  const [onJobWasherLocation, setWasherOnJobLocation] = useState([])
  const [allWasherLocation, setWasherAllLocation] = useState([])

  const { getWasherLiveLocation, washerLiveLocations } = useFirebase()

  useEffect(() => {
    //realDb 
    getWasherLiveLocation()
  }, [])

  const count = [
    {
      today: "Total Registered Customers",
      title: `${dashboard && dashboard.customerCount ? dashboard.customerCount : 0}`,
      persent: "10%",
      icon: <i className="fa fa-user"></i>,
      bnb: "bnb2",
      url: '/customer'
    },
    {
      today: "Total Registered Washer",
      title: `${dashboard && dashboard.washerCount ? dashboard.washerCount : 0}`,
      icon: <i className="fa fa-car"></i>,
      bnb: "bnb2",
      url: '/washer'
    },
    {
      today: "Total Sales Throughout (OMR)",
      title: `${dashboard && dashboard.totalEarning ? dashboard.totalEarning : 0}`,
      icon: <i className="fas fa-dollar-sign"></i>,
      bnb: "bnb2",
      url: '/transaction'
    },
    {
      today: "Most Picked Subscription",
      title: `${dashboard && dashboard.mostPickedSubscription ? `${dashboard.mostPickedSubscription.subscription.name} (${dashboard.mostPickedSubscription.count})` : '-'}`,
      icon: <i className="fas fa-check-circle"></i>,
      bnb: "redtext",
      url: `${dashboard && dashboard.mostPickedSubscription ? `/subscription/view/${dashboard.mostPickedSubscription.subscription_id}` : '-'}`
    },
  ];

  const customerMonthlyCount = (data) => {
    const Cmonths = data?.customerGraph?.customerMonthlyCount?.map(item => item?.month);
    const Ccounts = data?.customerGraph?.customerMonthlyCount?.map(item => item?.count);
    const customerChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: 'Customer Monthly',
          data: Ccounts,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
        },
      ],
    };
    setGraphCustomerData(customerChartData)
  }

  const customerWeeklyCount = () => {
    const Cmonths = dashboard?.customerGraph?.customerWeeklyCount?.map(item => item?.week);
    const Ccounts = dashboard?.customerGraph?.customerWeeklyCount?.map(item => item?.count);
    const customerChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: 'Customer Weekly',
          data: Ccounts,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgb(255, 159, 64)',
          borderWidth: 1,
        },
      ],
    };
    setGraphCustomerData(customerChartData)
  }

  const customerYearlyCount = () => {
    const Cmonths = dashboard?.customerGraph?.customerYearlyCount?.map(item => item?.year);
    const Ccounts = dashboard?.customerGraph?.customerYearlyCount?.map(item => item?.count);
    const customerChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: 'Customer Yearly',
          data: Ccounts,
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgb(255, 205, 86)',
          borderWidth: 1,
        },
      ],
    };
    setGraphCustomerData(customerChartData)
  }

  const washerMonthlyCount = (data) => {
    const Wmonths = data?.washerGraph?.washerMonthlyCount?.map(item => item?.month);
    const Wcounts = data?.washerGraph?.washerMonthlyCount?.map(item => item?.count);
    const washerChartData = {
      labels: Wmonths,
      datasets: [
        {
          label: 'Washer Monthly',
          data: Wcounts,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 1,
        },
      ],
    };
    setGraphWasherData(washerChartData)
  }

  const washerWeeklyCount = () => {
    const Wmonths = dashboard?.washerGraph?.washerWeeklyCount?.map(item => item?.week);
    const Wcounts = dashboard?.washerGraph?.washerWeeklyCount?.map(item => item?.count);
    const washerChartData = {
      labels: Wmonths,
      datasets: [
        {
          label: 'Washer Weekly',
          data: Wcounts,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1,
        },
      ],
    };
    setGraphWasherData(washerChartData)
  }

  const washerYearlyCount = () => {
    const Wmonths = dashboard?.washerGraph?.washerYearlyCount?.map(item => item?.year);
    const Wcounts = dashboard?.washerGraph?.washerYearlyCount?.map(item => item?.count);
    const washerChartData = {
      labels: Wmonths,
      datasets: [
        {
          label: 'Washer Yearly',
          data: Wcounts,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgb(153, 102, 255)',
          borderWidth: 1,
        },
      ],
    };
    setGraphWasherData(washerChartData)
  }

  const bookingMonthlyCount = (data) => {
    const Bmonths = data?.bookingGraph?.bookingMonthlyCount?.map(item => item?.month);
    const Bcounts = data?.bookingGraph?.bookingMonthlyCount?.map(item => item?.count);
    const bookingChartData = {
      labels: Bmonths,
      datasets: [
        {
          label: 'Booking Monthly',
          data: Bcounts,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1,
        },
      ],
    };
    setGraphBookingData(bookingChartData)
  }

  const bookingWeeklyCount = () => {
    const Bmonths = dashboard?.bookingGraph?.bookingWeeklyCount?.map(item => item?.week);
    const Bcounts = dashboard?.bookingGraph?.bookingWeeklyCount?.map(item => item?.count);
    const bookingChartData = {
      labels: Bmonths,
      datasets: [
        {
          label: 'Booking Weekly',
          data: Bcounts,
          backgroundColor: 'rgba(201, 203, 207, 0.2)',
          borderColor: 'rgb(201, 203, 207)',
          borderWidth: 1,
        },
      ],
    };
    setGraphBookingData(bookingChartData)
  }

  const bookingYearlyCount = () => {
    const Bmonths = dashboard?.bookingGraph?.bookingYearlyCount?.map(item => item?.year);
    const Bcounts = dashboard?.bookingGraph?.bookingYearlyCount?.map(item => item?.count);
    const bookingChartData = {
      labels: Bmonths,
      datasets: [
        {
          label: 'Booking Yearly',
          data: Bcounts,
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgb(255, 205, 86)',
          borderWidth: 1,
        },
      ],
    };
    setGraphBookingData(bookingChartData)
  }

  const options = {
    indexAxis: 'y',
  }

  const heatMapData = {
    positions: [
      { lat: 55.5, lng: 34.56 },
      { lat: 34.7, lng: 28.4 },
    ],
    options: {
      radius: 20,
      opacity: 0.6,
    }
  }

  const leastServiceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { service }) => {
        return service ? <a href={`product-service/view/${service._id}`} target="_blank" className="cap">{service.name}</a> : '-';
      },
    },
    {
      title: "Service(s) Count",
      dataIndex: "count",
      key: "count",
      render: (_, { count }) => {
        return (<Tag color={`green`} key={count}>{count}</Tag>);
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
  ];

  const mostServiceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { service }) => {
        return service ? <a href={`product-service/view/${service._id}`} target="_blank" className="cap">{service.name}</a> : '-';
      },
    },
    {
      title: "Service(s) Count",
      dataIndex: "count",
      key: "count",
      render: (_, { count }) => {
        return (<Tag color={`green`} key={count}>{count}</Tag>);
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
  ];

  const subscriptionColumns = [
    {
      title: "User Name",
      dataIndex: "user_id",
      key: "user_id",
      render: (_, { user_id }) => {
        return (
          <>
            <a className="cap" href={`/customer/view/${user_id._id}`}>
              {user_id && user_id.name ? user_id.name : '-'}
            </a>

            <div>
              {user_id && user_id.country_code ? `+${user_id.country_code}` : ''}
              {user_id && user_id.mobile_number ? `-${user_id.mobile_number}` : ''}
            </div>
          </>
        );
      },
    },
    {
      title: "Subscription Number",
      key: "subscription_no",
      dataIndex: "subscription_no",
      render: (_, { subscription_no }) => {
        return (subscription_no ? subscription_no : '-');
      },
    },
    {
      title: "Subscription Name",
      key: "subscription_name",
      dataIndex: "subscription_name",
      render: (_, { subscription_name }) => {
        return ( subscription_name && subscription_name ? <a className="cap">{subscription_name}</a> : '-' );
      },
    },
    {
      title: "Amount (" + PRICE + ")",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Expired On",
      key: "end_date",
      dataIndex: "end_date",
      render: (_, { end_date }) => {
        return (
          moment(end_date).format('DD-MMM-YYYY')
        );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
  ]

  const heatMapAllWasher = () => {
    setWasherLocation(allWasherLocation)
  }

  const heatMapAvailableWasher = () => {
    setWasherLocation(availableWasherLocation)
  }

  const heatMapOnJobWasher = () => {
    setWasherLocation(onJobWasherLocation)
  }

  useEffect(() => {
    setLoading(true)
    request({
      url: '/admin/dashboard/dashboard-data',
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setDashboard(data.data)
        customerMonthlyCount(data?.data)
        washerMonthlyCount(data?.data)
        bookingMonthlyCount(data?.data)
        setUserSubscriptionExpiringList(data?.data.userSubscriptionExpiringList)
        setMostSoldServiceList(data?.data.mostSoldServiceList)
        setLeastSoldServiceList(data?.data.leastSoldServiceList)

        setWasherAllLocation(data?.data.washerLocation)
        setWasherAvailableLocation(data?.data.availableWasher)
        setWasherOnJobLocation(data?.data.onJobWasher)
        heatMapAllWasher(data?.data.washerLocation)

        setWasherLocation(data?.data.washerLocation)

        setWasherLatLngLoading(false)
        setBarData(true);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }, [])

  return (
    <Main>
      <div className="layout-content">

        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col key={index} xs={24} sm={24} md={12} xxl={6} className="mb-24">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <Link to={c.url}>
                        <span>{c.today}</span>
                        <Title level={3}>{c.title}</Title>
                      </Link>
                    </Col>
                    <Col xs={6}> <div className="icon-box">{c.icon}</div></Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={12} xs={24} xl={12}>
            <Card bordered={false} className="criclebox tablespace mb-24"><p className="dashboard-table-heading">Most Sold (5) Services</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={mostServiceColumns}
                  dataSource={mostSoldServiceList}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
          <Col span={12} xs={24} xl={12}>
            <Card bordered={false} className="criclebox tablespace mb-24"><p className="dashboard-table-heading">Least Sold (5) Services</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={leastServiceColumns}
                  dataSource={leastSoldServiceList}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3" gutter={[24, 0]}>
          <Col span={24}>
            <Card bordered={false} className="criclebox tablespace mb-24"><p className="dashboard-table-heading">User Subscription (10) Expiring ({moment().format('MMMM')})</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={subscriptionColumns}
                  dataSource={userSubscriptionExpiringList}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3" gutter={[24, 0]}>
          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title level={5}>Customer</Title>
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button onClick={customerWeeklyCount} value="week">Week</Radio.Button>
                  <Radio.Button onClick={() => customerMonthlyCount(dashboard)} value="month">Month</Radio.Button>
                  <Radio.Button onClick={customerYearlyCount} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={customerGraphData} options={options} />}</>}

            </Card>
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title level={5}>Washer</Title>
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button onClick={washerWeeklyCount} value="week">Week</Radio.Button>
                  <Radio.Button onClick={() => washerMonthlyCount(dashboard)} value="month">Month</Radio.Button>
                  <Radio.Button onClick={washerYearlyCount} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={washerGraphData} options={options} />}</>}
            </Card>
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title level={5}>OnDemand Booking</Title>
              <Row style={{ justifyContent: "end" }}>
                <Radio.Group defaultValue="month" buttonStyle="solid">
                  <Radio.Button onClick={bookingWeeklyCount} value="week">Week</Radio.Button>
                  <Radio.Button onClick={() => bookingMonthlyCount(dashboard)} value="month">Month</Radio.Button>
                  <Radio.Button onClick={bookingYearlyCount} value="year">Year</Radio.Button>
                </Radio.Group>
              </Row>
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={bookingGraphData} options={options} />}</>}
            </Card>
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="mapCard criclebox h-full">
              <Title level={5}>HeatMap (Washer)</Title>

              <Row style={{ justifyContent: "end" }} className="mb-1">
                <Radio.Group defaultValue="washer" buttonStyle="solid">
                  <Radio.Button onClick={heatMapAvailableWasher} value="available">Available</Radio.Button>
                  <Radio.Button onClick={heatMapAllWasher} value="washer">All Washer</Radio.Button>
                  <Radio.Button onClick={heatMapOnJobWasher} value="on_job">On Job</Radio.Button>
                </Radio.Group>
              </Row>

              <div className="dashboardMapOuter" style={{ width: "100%", height: "calc(100% - 82px)", transform: "translate(0,0)" }}>
                <GoogleHeatMap location={washerLocation} />
              </div> 

            </Card>
          </Col> 

          <Col xs={24} xl={24} className="mb-24">
            <Card bordered={false} className="mapCard criclebox h-full">
              <Title level={5}>Live Location (Washer)</Title>

              <div style={{ width: "100%", height: "calc(100% - 82px)", transform: "translate(0,0)" }}>
                <GoogleMap locations={washerLiveLocations} />
              </div>

            </Card>
          </Col>

      
        </Row>

      </div>
    </Main>
  );
}

export default Home;
