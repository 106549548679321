import { Row, Col, Form, Modal, Select, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";

export const ChangeStatus = ({ show, hide, orderid, statusRefresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [compeleted, setCompeleted] = useState();
  const [loading, setLoading] = useState(false)
  const [orderServices, setOrderServices] = useState([]);

  const orderStatus = [
    /* {
      value: 'confirm',
      label: 'Job Confirmed',
    },
    {
      value: 'reach',
      label: 'Reached at Location',
    },
    {
      value: 'in-progress',
      label: 'In-Progress (Service Start)',
    }, */
    {
      value: 'complete',
      label: 'Completed',
    },
    {
      value: 'cancel',
      label: 'Cancelled',
    },
  ];

  const handleOnChange = (value) => {
    setCompeleted(value);
  }

  const getOrderServicesList = () => {
    const payload     = {};
    payload.order_id  = orderid; 
    request({
      url: apiPath.orderServices,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setOrderServices(data.data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const onStatus = (values) => {
    const { status, service_id } = values
    const payload = {}
    setLoading(true)
    payload.order_id    = orderid;
    payload.service_id  = service_id;
    payload.status      = status;
    request({
      url: apiPath.statusOrder,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          statusRefresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  useEffect(() => {
    getOrderServicesList()
  }, [])

  return (
    <Modal
      visible={show}
      width={600}
      title='Select Order Status'
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'status',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="status" form={form} onFinish={onStatus} layout="vertical">
        <Row>
          <Col span={24} style={{ marginLeft: "15px" }}>
            <Form.Item label="Select Order status" name="status"
              rules={[
                { required: true, message: "Please select the status!" },
              ]}
            >
              <Select onChange={handleOnChange} options={orderStatus} placeholder="Select Order Status"></Select>
            </Form.Item>
          </Col>

          {compeleted == 'complete' ? 
          <>
            <Col span={24} style={{ marginLeft: "15px" }}>
              <Form.Item label="Select Complete Services" name="service_id"
                rules={[
                  { required: true, message: "Please select the services!" },
                ]}
              >
                <Select placeholder="Select Complete Services" mode="multiple">
                  {orderServices && orderServices && orderServices.length > 0 ? orderServices.map((item, index) => (
                      <option key={index} value={item._id}>
                          <span className="cap"> {item.name} </span>
                      </option>
                  )) : null}
                </Select>

              </Form.Item>

                  <div className="mt-2">

              <span className="note-redtext">Note : </span> <span className="font-semibold">The order cannot be marked as complete until all services are marked as done.</span>
                  </div>
            </Col>

            </>
          : null }

        </Row>
      </Form>
    </Modal>
  )
}
