import { Row, Col, Card, Table, Button, Modal, Form, Tag, Tooltip, Input } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import Main from "../../../components/layout/Main";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiPath from "../../../constants/apiPath";
import ShowTotal from "../../../components/ShowTotal";
import moment from 'moment';

function Index() {

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { confirm } = Modal;

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);

  };

  const columns = [
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (_, { color }) => {
        return ( <button className="carcolor" style={{ border: color, backgroundColor : color }}></button> );
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a> );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="Update Color" color={"purple"} key={"updatecolor"}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i class="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];


  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusCarColor + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: apiPath.listCarColor + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Vehicle Color"
              extra={
                <>
                <Button onClick={(e) => setVisible(true)}>Add Vehicle Color</Button>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
 
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })
  }, [data])


  const onCreate = (values) => {
    const { color } = values
    const payload = {}
    setLoading(true)
    payload.color = color;
    request({
      url: `${data ? apiPath.addEditCarColor + "/" + data._id : apiPath.addEditCarColor}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Vehicle Color' : 'Create a New Vehicle Color'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label="Select Color"
              name="color"
              rules={[
                { required: true, message: "Please select the color!" },
              ]}
            >
              <Input type="color" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Index;