import { Row, Col, Card, Button, Modal, Skeleton } from "antd";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { PRICE } from "../../constants/conts";
import moment from 'moment';
import Loader from "../../components/Loader";

function View() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewCashEntry + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <Card title="Cash Entry Details">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? <Skeleton active /> :
              <div className="view-main-list">

              <div className="view-inner-cls">
                <h5>Order ID:</h5>
                <h6>{list && list.order_id && list.order_id.random_order_id ? list.order_id.random_order_id : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Customer Name:</h5>
                <h6 className="cap">{list && list.customer_id && list.customer_id.name ? <Link className="cap" to={`/customer/view/${list.customer_id._id}`}>{list.customer_id.name}</Link> : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Customer Location:</h5>
                <h6 className="cap">{list && list.order_id && list.order_id.address ? list.order_id.address : '-'}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>{'Amount ('+PRICE +')'}:</h5>
                <h6>{list.amount}</h6>
              </div>

              <div className="view-inner-cls">
                <h5>Washer Name:</h5>
                <h6 className="cap">{list && list.washer_id && list.washer_id.name ? <Link className="cap" to={`/washer/view/${list.washer_id._id}`}>{list.washer_id.name}</Link> : '-'}</h6>
              </div>

              {/* <div className="view-inner-cls">
                <h5>Date & Time of Service:</h5>
                <h6>{list && list.date_time_service ? moment(list.date_time_service).format('DD-MMM-YYYY, hh:mm A') : ""}</h6>
              </div> */}

              <div className="view-inner-cls">
                <h5>Created On:</h5>
                <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
              </div>

              <div className="view-inner-cls float-right">
                <Link className="ant-btn ant-btn-default" to="/cash-entries/">Back</Link>
              </div>

              </div>
            }
          </Col>
        </Row>
      </Card>
    </Main >
  );
}


export default View;
