import { Row, Col, Card, Button, Skeleton, Rate, Image, Tag, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import moment from 'moment';
import { PRICE } from "../../constants/conts";
import { Badge } from 'antd';
import notfound from "../../assets/images/not_found.png"

function OnDemandView() {

  const params                    =   useParams();
  const { request }               =   useRequest();
  const [list, setList]           =   useState({});
  const [history, setHisory]      =   useState([]);
  const [serviceList, setServiceList]      =   useState([]);
  const [carColor, setCarColor]   =   useState('');
  const [loading, setLoading]     =   useState(false);
  const [visible, setVisible]     =   useState(false);
  const [refresh, setRefresh]     =   useState(false)
  const [selected, setSelected]   =   useState();
  const ratingStatus              =   ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewOrder + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.getOrder);
        setHisory(data.data.getOrderStatus);
        setCarColor(data.data.getOrder?.car_id?.color_id?.color);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  
  const columns = [
    {
      title: "Status",
      key: "status",
      render: (_, { status, _id }) => {
        let color = status === "complete" ? "green" : status === "cancel" ? "red" : status === "accept" ? "cyan" : "yellow" ;
        return (
          <a><Tag color={color} key={status}>
            {
              status === "assign" ? "Assigned" : 
              status === "accept" ? "Accepted" : 
              status === "confirm" ? "Confirmed" : 
              status === "reach" ? "Reached" : 
              status === "in-progress" ? "In-Progress" : 
              status === "complete" ? "Completed" : 
              status === "cancel" ? "Cancelled" : 
              "Un-Assigned"
            }
          </Tag></a>
        );
      },
    },
    {
      title: "Marked By",
      key: "mark_by",
      dataIndex: "mark_by",
      render: (_, { mark_by }) => {
        return (
          mark_by ? <span className="cap">{mark_by.name}</span> : '-'
        );
      },
    },
    {
      title: "Marked On",
      key: "mark_on",
      dataIndex: "mark_on",
      render: (_, { mark_on }) => {
        return (
          moment(mark_on).format('DD-MMM-YYYY, hh:mm A')
        );
      },
    },
  ];


  const serviceColumns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image, name }) => {
        return (
          <>
            <Image className="image-index-radius" src={image ? image : notfound} /> 
            <span className="cap" style={{ marginLeft: 12 }}>{name}</span>
          </>
        );
      },
    },
    {
      title: "Price ("+PRICE +")",
      key: "price",
      dataIndex: "price",
      render: (_, { price }) => {
        return price ? price : '-';
      },
    },
    {
      title: "Features Include",
      key: "feature_list",
      dataIndex: "feature_list",
      render: (_, { feature_list }) => {
        if (feature_list && Array.isArray(feature_list)) {
          return (
            <ul>
              {feature_list.map((feature, index) => (
                <li className="cap" style={{marginLeft: "-20px"}} key={index}>{feature}</li>
              ))}
            </ul>
          );
        } else {
          return '-';
        }
      },
    },
    {
      title: "Current Status",
      key: "is_complete",
      render: (_, { is_complete, _id }) => {
        let color = is_complete === true ? "green" : "red";
        return (
          <a>
            <Tag color={color} key={is_complete}> { is_complete === true ? "Completed" : "Pending" } </Tag>
          </a>
        );
      },
    },
    {
      title: "Updated On",
      key: "complete_on",
      dataIndex: "complete_on",
      render: (_, { complete_on }) => {
        return (
          complete_on ? moment(complete_on).format('DD-MMM-YYYY, hh:mm A') : '-'
        );
      },
    },
  ];

  useEffect(() => {
    fetchData(params.id);
  }, [refresh])

  return (
    <Main>

      <Row gutter={16}>
        <Col span={12}>
          <Card title="Order Details" bordered={false}>
            
            {loading ? [1,2,3,4].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Order ID:</h5>
                  <h6>{list.random_order_id}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Order Type:</h5>
                  <h6>{list.order_type}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer Name:</h5>
                  <h6 className="cap">{list && list.user_id && list.user_id.name ? <Link className="cap" to={`/customer/view/${list.user_id._id}`}>{list.user_id.name}</Link> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer Email:</h5>
                  <h6>{list && list.user_id && list.user_id.email ? list.user_id.email : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Customer Contact No.:</h5>
                  <h6>{list.user_id ? (list.user_id.country_code ? "+" + list.user_id.country_code + "-" : "+968") + (list.user_id.mobile_number ? list.user_id.mobile_number : '') : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Washer Name:</h5>
                  <h6 className="cap">{list && list.washer_id && list.washer_id.name ? <Link className="cap" to={`/washer/view/${list.washer_id._id}`}>{list.washer_id.name}</Link> : '-'}</h6>
                </div>
                
                <div className="view-inner-cls">
                  <h5>Washer Contact No.:</h5>
                  <h6>{list.washer_id ? (list.washer_id.country_code ? "+" + list.washer_id.country_code + "-" : "+968") + (list.washer_id.mobile_number ? list.washer_id.mobile_number : '') : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Rating Received:</h5>
                  <h6>{list.is_rate ? "Yes" : "No"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{
                    list.status === 'complete' ? 
                    <Badge colorSuccess status="success" text="Completed" /> :
                    list.status === 'cancel' ? <Badge status="error" text="Cancelled" /> :
                    list.status === 'in-progress' ? <Badge status="cyan" text="In-Progress" /> 
                    : <Badge status="yellow" text="Un-Assigned Order" />
                    }
                    </h6>
                </div>               

                <div className="view-inner-cls">
                  <h5>Service Date & Time:</h5>
                  <h6>{list.date_time_stamp ? moment(list.date_time_stamp).format('DD-MMM-YYYY, hh:mm A') : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY, hh:mm A') : '-'}</h6>
                </div>
               
              </div>
            }

          </Card>
        </Col>

        <Col span={24} sm={24} xl={12}>
          <Row gutter={16}>
            <Col span={24} sm={24} xl={24}>
              <Card title="Car Details" className="h-100" bordered={false}>
                {loading ? <Skeleton active /> :
                  <div className="view-main-list">

                    <div className="view-inner-cls">
                      <h5>Avatar:</h5>
                      <h6> { list?.car_id?.avatar_id?.image ? 
                            <Image width={50} height={50} src={list?.car_id?.avatar_id?.image}></Image> : <span className="text-danger">Image Not Available</span> }
                        </h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Color:</h5>
                      <h6>
                        {
                          carColor ? <button className="carcolor" style={{ border: carColor ? carColor : '#ffffff00' , backgroundColor : carColor ? carColor : '#ffffff00' }}></button> : <span className="text-danger">Color Not Available</span>
                        }
                        </h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Car :</h5>
                      <h6>
                        {(() => {
                          const carDetails = [
                              list?.car_id?.type_id?.name,
                              list?.car_id?.make_id?.name,
                              list?.car_id?.model_id?.name,
                              list?.car_id?.car_number
                          ].filter(Boolean).join(' - ');

                          return carDetails || <span className="text-danger">Car Details Not Found</span>;
                        })()}
                      </h6>
                    </div>
                  </div>
                }
              </Card>
            </Col>

            <Col span={24} sm={24} xl={24} className="mt-3">
              <Card title="Address" className="h-100">
                {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">

                    <div className="view-inner-cls">
                      <h5>Location:</h5>
                      <h6>
                        {list && list.city ? list.city : ''}
                        {list && list.state ? " - " + list.state : ''}
                        {list && list.country ? " - " + list.country : ''}
                      </h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Landmark:</h5>
                      <h6 className="cap">{list && list.landmark ? list.landmark : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>House No:</h5>
                      <h6 className="cap">{list && list.house_no ? list.house_no : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Address:</h5>
                      <h6 className="cap">{list && list.address ? list.address : '-'}</h6>
                    </div>

                  </div>
                }
              </Card>
            </Col>
          </Row>
        </Col>

        <Col span={24} sm={24} xl={12} className="mt-3">
          <Card title="Payment Details" className="h-100">
            {loading ? [1,2].map(item => <Skeleton active key={item} />) :

              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Discount Amount{list.discount_id && list.discount_id.code ? ' (' + list.discount_id.code + ')' : null}:</h5>
                  <h6>{list.discount_amount ? list.discount_amount + ' (' + PRICE + ')' : '-'}</h6>
                </div>

                {/* <div className="view-inner-cls">
                  <h5>{'Sub Total ('+PRICE +')'}:</h5>
                  <h6>{list.sub_total}</h6>
                </div> */}

                <div className="view-inner-cls">
                  <h5>Tariff Charges:</h5>
                  <h6>{list.tariff_charge ? list.tariff_charge  + ' (' + PRICE + ')' : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Grand Total:</h5>
                  <h6>{list.total ? list.total  + ' (' + PRICE + ')' : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6 className="cap">{ list.payment_status ? list.payment_status : 'Pending'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Payment Mode:</h5>
                  <h6 className="cap">{ list.payment_method ? list.payment_method : '-'}</h6>
                </div>

                { list.payment_status == 'paid' ?
                  <>
                    <div className="view-inner-cls">
                      <h5>Received On:</h5>
                      <h6>{list.payment_received_on ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Payment Session ID:</h5>
                      <h6>{list.payment_session_id ? list.payment_session_id : '-'}</h6>
                    </div>
                  </>
                : null }

                {/* <div className="view-inner-cls">
                  <h5>OTP Verified:</h5>
                  <h6>{ list.is_otp_verify ? <Tag color="green" > Verified </Tag> : <Tag color="red"> Not Verified </Tag> }</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>OTP Verified On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY, hh:mm A') : '-'}</h6>
                </div> */}

              </div>
            }
          </Card>
        </Col>

        <Col span={24} sm={24} xl={12}  className="mt-3">
          <Card title="Rating & Review" className="h-100">
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">

                    <div className="view-inner-cls">
                      <h5>Rating:</h5>
                      <h6> <Rate tooltips={ratingStatus} value={list.rate} /></h6>
                    </div>
  
                    <div className="view-inner-cls">
                      <h5>Review:</h5>
                      <h6>{list && list.review ? list.review : '-'}</h6>
                    </div>

                  </div>
                }
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Card title="Services Details" className="mt-3">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Table
                  loading={loading}
                  columns={serviceColumns}
                  dataSource={list.service_id}
                  className="ant-border-space"
                />
              </div>
            }
          </Col>
        </Row>
      </Card>
      
      <div className="view-inner-cls float-right mt-3">
        <Link className="ant-btn ant-btn-default" to="/on-demand-order/">Back</Link>
      </div>

      {/* <Card title="Order History" className="mt-3">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={history}
                  className="ant-border-space"
                />
              </div>
            }
          </Col>
        </Row>
      </Card> */}

    </Main >
  );
}


export default OnDemandView;
