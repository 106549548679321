import { Menu, Button, Modal } from "antd";
import { useState, useContext,useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { QuestionCircleFilled } from '@ant-design/icons';
import Logo from "../../assets/images/Logo.png";

function Sidenav({ color }) {

  const { pathname }  =   useLocation();
  const page          =   pathname.replace("/", "");
  const { logout }    =   useContext(AuthContext)
  const { confirm }   =   Modal;
  const [menuMode, setMenuMode] = useState('vertical');
  
  const showDeleteConfirm = (record) => {
    setTimeout(() => {
      confirm({
        okText: 'Logout',
        okType: 'danger',
        icon: <QuestionCircleFilled />,
        cancelText: 'Cancel',
        content: <Button >Are you sure you want to logout ?</Button>,
        onOk() {
          logout();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const isActiveLink = (link) => {
    return pathname === link;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode('inline');
      } else {
        setMenuMode('vertical');
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isOrderDropdownOpen = isActiveLink('/on-demand-order') || isActiveLink('/subscription-order');
  const isVehicleDropdownOpen = isActiveLink('/vehicle') || isActiveLink('/vehicle-brand') || isActiveLink('/vehicle-model') || isActiveLink('/vehicle-color') || isActiveLink('/vehicle-avatar') || isActiveLink('/vehicle-type');

  return (
    <>
      <div className="brand w-100">
        <NavLink to="/dashboard" className="imgOuter">
          <img  src={Logo} alt="logo" />
        </NavLink>
      </div>
      <Menu
        defaultOpenKeys={
          isOrderDropdownOpen
            ? ["OrderSubMenu"]
            : isVehicleDropdownOpen
            ? ["VehicleSubMenu"]
            : []
        }
        theme="light"
        mode={`inline`}
      >
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{ background: page === "dashboard" ? color : "" }}
            >
              <i className="fas fa-tachometer-alt"></i>
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="2">
          <NavLink to="/washer">
            <span
              className="icon"
              style={{ background: page === "washer" ? color : "" }}
            >
              <i className="fas fa-car"></i>
            </span>
            <span className="label">Washer Manager</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="3">
          <NavLink to="/customer">
            <span
              className="icon"
              style={{ background: page === "customer" ? color : "" }}
            >
              <i className="fa fa-user"></i>
            </span>
            <span className="label">Customer Manager</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="4">
          <NavLink to="/vehicle-type">
            <span className="icon" style={{ background: page === "vehicle-type" ? color : "" }}><i className="fas fa-car"></i></span>
            <span className="label">Vehicle Type Manager</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.SubMenu
          key="VehicleSubMenu"
          className={`customDropDown ${
            pathname.match(/^\/vehicle-brand/) ||
            pathname.match(/^\/vehicle-model/) ||
            pathname.match(/^\/vehicle-type/) ||
            pathname.match(/^\/vehicle/) ||
            pathname.match(/^\/vehicle-avatar/) ||
            pathname.match(/^\/vehicle-color/)
              ? "activeDropDown"
              : ""
          }`}
          title={
            <>
              <div className="customDorpItem">
                <span className="icon">
                  <i className="fa fa-car"></i>
                </span>
                <span className="label">Vehicle Manager</span>
              </div>
            </>
          }
        >
          <Menu.Item key="3-1">
            <NavLink to="/vehicle-avatar">Avatar Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="3-2">
            <NavLink to="/vehicle-color">Color Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="3-3">
            <NavLink to="/vehicle-type">Type Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="3-4">
            <NavLink to="/vehicle-brand">Brand Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="3-5">
            <NavLink to="/vehicle-model">Model Manager</NavLink>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu
          key="OrderSubMenu"
          className={`customDropDown ${
            pathname.match(/^\/on-demand-order/) ||
            pathname.match(/^\/subscription-order/)
              ? "activeDropDown"
              : ""
          }`}
          title={
            <>
              <div className="customDorpItem">
                <span className="icon">
                  <i className="fas fa-shopping-bag"></i>
                </span>
                <span className="label">Order Manager</span>
              </div>
            </>
          }
        >
          <Menu.Item key="7-1">
            <NavLink to="/on-demand-order">On Demand Order</NavLink>
          </Menu.Item>
          <Menu.Item key="7-2">
            <NavLink to="/subscription-order">Subscription Order</NavLink>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="5">
          <NavLink to="/discount">
            <span
              className="icon"
              style={{ background: page === "discount" ? color : "" }}
            >
              <i className="fa fa-tag"></i>
            </span>
            <span className="label">Discount Manager</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/subscription">
            <span
              className="icon"
              style={{ background: page === "subscription" ? color : "" }}
            >
              <i className="fa fa-user"></i>
            </span>
            <span className="label">Subscription Manager</span>
          </NavLink>
        </Menu.Item>

        {/* <Menu.Item key="7">
          <NavLink to="/order">
            <span className="icon" style={{ background: page === "order" ? color : "" }}><i className="fas fa-shopping-bag"></i></span>
            <span className="label">Order Manager</span>
          </NavLink>
        </Menu.Item> */}

        <Menu.Item key="8">
          <NavLink to="/transaction">
            <span
              className="icon"
              style={{ background: page === "transaction" ? color : "" }}
            >
              <i className="fa fa-list-alt"></i>
            </span>
            <span className="label">Transaction Manager</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="9">
          <NavLink to="/cash-entries">
            <span
              className="icon"
              style={{ background: page === "cash-entries" ? color : "" }}
            >
              <i className="fas fa-wallet"></i>
            </span>
            <span className="label">Cash Entry Manager</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="10">
          <NavLink to="/product-service">
            <span
              className="icon"
              style={{ background: page === "product-service" ? color : "" }}
            >
              <i className="fa fa-wrench"></i>
            </span>
            <span className="label">Product/Service Catalogue</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="11">
          <NavLink to="/banner">
            <span
              className="icon"
              style={{ background: page === "banner" ? color : "" }}
            >
              <i className="fas fa-image"></i>
            </span>
            <span className="label">Banner Manager</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="12">
          <NavLink to="/content">
            <span
              className="icon"
              style={{ background: page === "content" ? color : "" }}
            >
              <i className="fa fa-file"></i>
            </span>
            <span className="label">Content Manager</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="13">
          <NavLink to="/email-template">
            <span
              className="icon"
              style={{ background: page === "email-template" ? color : "" }}
            >
              <i className="fas fa-envelope"></i>
            </span>
            <span className="label">Email Template Manager</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="14">
          <NavLink to="/" onClick={() => showDeleteConfirm()}>
            <span className="icon">
              <i className="fas fa-sign-out-alt"></i>
            </span>
            <span className="label">Logout</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );

}

export default Sidenav;
