import { Row, Col, Card, Button, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import { PRICE } from "../../constants/conts";
import moment from "moment";

function View() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url: apiPath.viewSubscription + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <Card title="Subscription Details">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? [1,2].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Name:</h5>
                  <h6>{list.name}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{'Original Price ('+PRICE +')'}:</h5>
                  <h6>{list.price}/Month</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{'Discount Price ('+PRICE +')'}:</h5>
                  <h6>{list.discount}/Month</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>From Date:</h5>
                  <h6>{list.from_date}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>To Date:</h5>
                  <h6>{list.to_date}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Services Include:</h5>
                  <Row className="text-right">
                    <Col span={24}>
                      {list && list.services && list.services.length > 0 && list.services.map((item, index) => 
                        <Col className="mt-1"><h6>
                          <Link className="cap" to={`/product-service/view/${item.service_id._id}`}>  {index+1}) {item.service_id.name} </Link> ({item.no_of_time} time(s))   </h6></Col>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-default" to="/subscription/">Back</Link>
                </div>

              </div>
            }

          </Col>
        </Row>
      </Card>
    </Main>
  );
}

export default View;
