import { Row, Col, Card, Button, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from "moment";

function View() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url: apiPath.viewContent + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <Card title="Content Details" className="contentManager">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? <Skeleton active /> : 
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Name (En):</h5>
                  <h6>{list.name}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Description (En):</h5>
                  <h6>{list.description ? <p dangerouslySetInnerHTML={{__html: list.description}}></p>   : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Name (Ar):</h5>
                  <h6>{list.ar_name}</h6>
                </div>
               
                <div className="view-inner-cls">
                  <h5>Description (Ar):</h5>
                  <h6>{list.ar_description ? <p dangerouslySetInnerHTML={{__html: list.ar_description}}></p>   : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-default" to="/content/">Back</Link>
                </div>

              </div>
            }
            
          </Col>
        </Row>
      </Card>
    </Main>
  );
}

export default View;
