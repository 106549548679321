import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';
import React, { useContext, Suspense } from 'react';
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import PrivateRoute from './components/PrivateRoute'
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Error from './pages/Error';

import Customer from './pages/Customer/Index';
import CustomerView from './pages/Customer/View';

import VehicleBrand from './pages/Vehicle/Brand/Index';
import VehicleModel from './pages/Vehicle/Model/Index';
import VehicleAvatar from './pages/Vehicle/Avatar/Index';
import VehicleColor from './pages/Vehicle/Color/Index';
import VehicleType from './pages/Vehicle/Type/Index';

import Washer from './pages/Washer/Index';
import WasherView from './pages/Washer/View';

import Banner from './pages/Banner/Index';

import Subscription from './pages/Subscription/Index';
import SubscriptionView from './pages/Subscription/View';
import SubscriptionAdd from './pages/Subscription/Add';
import SubscriptionEdit from './pages/Subscription/Edit';

import Content from './pages/Content/Index';
import ContentView from './pages/Content/View';
import ContentAdd from './pages/Content/Add';
import ContentEdit from './pages/Content/Edit';

import ProductService from './pages/ProductService/Index';
import ProductServiceView from './pages/ProductService/View';
import ProductServiceAdd from './pages/ProductService/Add';
import ProductServiceEdit from './pages/ProductService/Edit';

import SubscriptionOrder from './pages/Order/Subscription';
import OnDemandOrder from './pages/Order/OnDemand';
import OrderSubscriptionView from './pages/Order/SubscriptionView';
import OrderOnDemandView from './pages/Order/OnDemandView';

import Discount from './pages/Discount/Index';
import DiscountView from './pages/Discount/View';

import CashEntries from './pages/CashEntries/Index';
import CashEntriesView from './pages/CashEntries/View';

import Transaction from './pages/Transaction/Index';
import TransactionView from './pages/Transaction/View';

import PaymentSuccess from './components/payment/Success';
import PaymentCancel from './components/payment/Cancel';

import EmailTemplate from './pages/EmailTemplate/Index';
import EmailTemplateView from './pages/EmailTemplate/View';
import EmailTemplateEdit from './pages/EmailTemplate/Edit';
import StaticPage from './pages/StaticPage';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccountSteps from './pages/DeleteAccountSteps';

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {

  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {

  const { isLoggedIn } = useContext(AuthContext)

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      {/* <Route path="/" element={<SignIn />} /> */}

      <Route path="/pages/:slug" element={<StaticPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/delete-account" element={<DeleteAccountSteps />} />

      {/* This both payment route will be use for mobile application (Web View) */}
      <Route path="/payment/success/:order_id" element={<PaymentSuccess />} />
      <Route path="/payment/cancel/:order_id" element={<PaymentCancel />} />
      <Route path="/payment/return/:order_id" element={<PaymentCancel />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            {" "}
            <Layout />{" "}
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />

        {/* Washer Routes */}
        <Route exact path="/washer" element={<Washer />} />
        <Route exact path="/washer/view/:id" element={<WasherView />} />

        {/* CashEntries Routes */}
        <Route exact path="/cash-entries" element={<CashEntries />} />
        <Route
          exact
          path="/cash-entries/view/:id"
          element={<CashEntriesView />}
        />

        {/* Discount Routes */}
        <Route exact path="/discount" element={<Discount />} />
        <Route exact path="/discount/view/:id" element={<DiscountView />} />

        {/* Customer Routes */}
        <Route exact path="/customer" element={<Customer />} />
        <Route exact path="/customer/view/:id" element={<CustomerView />} />

        {/* Vehicle Brand Model Routes */}
        <Route exact path="/vehicle-brand" element={<VehicleBrand />} />
        <Route exact path="/vehicle-model" element={<VehicleModel />} />
        <Route exact path="/vehicle-avatar" element={<VehicleAvatar />} />
        <Route exact path="/vehicle-color" element={<VehicleColor />} />
        <Route exact path="/vehicle-type" element={<VehicleType />} />

        {/* Banner Routes */}
        <Route exact path="/banner" element={<Banner />} />

        {/* Order Routes */}
        <Route
          exact
          path="/subscription-order"
          element={<SubscriptionOrder />}
        />
        <Route exact path="/on-demand-order" element={<OnDemandOrder />} />
        <Route
          exact
          path="/on-demand-order/view/:id"
          element={<OrderOnDemandView />}
        />
        <Route
          exact
          path="/subscription-order/view/:id"
          element={<OrderSubscriptionView />}
        />

        {/* Transaction Routes */}
        <Route exact path="/transaction" element={<Transaction />} />
        <Route
          exact
          path="/transaction/view/:id"
          element={<TransactionView />}
        />

        {/* Subscription Routes */}
        <Route exact path="/subscription" element={<Subscription />} />
        <Route exact path="/subscription/add" element={<SubscriptionAdd />} />
        <Route
          exact
          path="/subscription/update/:id?"
          element={<SubscriptionEdit />}
        />
        <Route
          exact
          path="/subscription/view/:id"
          element={<SubscriptionView />}
        />

        {/* Content Routes */}
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/content/add" element={<ContentAdd />} />
        <Route exact path="/content/update/:id?" element={<ContentEdit />} />
        <Route exact path="/content/view/:id" element={<ContentView />} />

        {/* ProductService Routes */}
        <Route exact path="/product-service" element={<ProductService />} />
        <Route
          exact
          path="/product-service/add"
          element={<ProductServiceAdd />}
        />
        <Route
          exact
          path="/product-service/update/:id?"
          element={<ProductServiceEdit />}
        />
        <Route
          exact
          path="/product-service/view/:id"
          element={<ProductServiceView />}
        />

        {/* EmailTemplate Routes */}
        <Route exact path="/email-template" element={<EmailTemplate />} />
        <Route
          exact
          path="/email-template/update/:id?"
          element={<EmailTemplateEdit />}
        />
        <Route
          exact
          path="/email-template/view/:id"
          element={<EmailTemplateView />}
        />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
}

const Layout = () => {
  return (<> <Outlet />  </>
  )
}

export default App;
