import * as XLSX from 'xlsx';
import moment from 'moment';

const DownloadExcel = (data, filename) => {
    const mainHeading = filename + ' Excel Data, Export on ' + moment().format('DD-MM-YYYY');
    const workbook  = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    // XLSX.utils.sheet_add_aoa(worksheet, [[mainHeading]], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, `${moment().milliseconds() + 1000 * (moment().seconds() + 60 * 60)+ `-${filename}`}.xlsx`);
    return true;
};

const SampleFileDownload = (sectionName) => {
    let fileUrl       =   process.env.PUBLIC_URL + '/sample.xlsx';
    if(sectionName == 'Brand'){
        fileUrl   = process.env.PUBLIC_URL + '/Sample-Brand.xlsx';
    }else{
        fileUrl   = process.env.PUBLIC_URL + '/Sample-Model.xlsx';
    }
    const link      = document.createElement('a');
    link.href       = fileUrl;
    link.download   = `Sample-${moment().milliseconds() + 1000 * (moment().seconds() + 60 * 60)}-${sectionName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export { DownloadExcel, SampleFileDownload };
