import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import useLocation from '../hooks/useLocation';

const GoogleMap = ({ locations }) => {

    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selected, setSelected] = useState(null);
    const [activeInfoWindow, setActiveInfoWindow] = useState(null);

    const { getCurrentLocation } = useLocation()

    useEffect(() => {
        const loadMap = async () => {
            let location
            try {
                location = await getCurrentLocation({ isReturn: true })
            } catch (err) {
                location= err
                console.log(err)
            }
            console.log(location, 'location');
            const mapOptions = {//51.442651, -0.147625
                center: location,
                zoom: 11,
            };
            const map = new window.google.maps.Map(document.getElementById('mapView'), mapOptions);
            setMap(map);
        };

        loadMap();
    }, []);

    useEffect(() => {
        if (map) {
            const markers = [];
            locations.forEach(location => {
                const marker = new window.google.maps.Marker({
                    position: {
                        lat: parseFloat(location.latitude),
                        lng: parseFloat(location.longitude)
                    },
                    map,
                    title: `${location.id}`,
                    // icon: '/favicon.png',
                    // shape: { coords: [1, 1, 1, 20, 18, 20, 18, 1], type: 'poly' },
                    animation: window.google.maps.Animation.DROP,
                    label: { text: 'P', fontSize: '14px', fontFamily: 'Arial', color: 'white' },
                    clickable: true,
                    //	draggable: true,
                    //visible: true
                });

                const infoWindow = new window.google.maps.InfoWindow({
                    content: `<div style="color: black;">${location.id}.</div>`,
                    maxWidth: 200,
                    //  pixelOffset: { width: 0, height: -20 },
                    // zIndex: 1,
                    //  disableAutoPan: false,
                    // closeOnClick: true,
                    // backgroundClassName: 'custom-background-class',
                    //  pane: 'floatPane',
                    // enableEventPropagation: false
                });

                marker.addListener('click', (event) => {
                    // if (activeInfoWindow) {
                    //     activeInfoWindow.close();
                    // }
                    // infoWindow.open({
                    //     anchor: marker,
                    //     shouldFocus: false,
                    //     map
                    // });
                    setActiveInfoWindow(infoWindow)
                    setSelected(location)
                    setSelectedMarker(marker);
                });
                markers.push(marker);
            });
            setMarkers(markers);
        }
    }, [map,locations]);

    useEffect(() => {
        if (!selectedMarker && !selected) return
        const infoWindow = new window.google.maps.InfoWindow({
            content: `<div style="color: black;">${selected.id}.</div>`,
            maxWidth: 200,
            pixelOffset: { width: 0, height: -20 },
            zIndex: 1,
            disableAutoPan: false,
            closeOnClick: true,
            backgroundClassName: 'custom-background-class',
            pane: 'floatPane',
            enableEventPropagation: false
        });
        infoWindow.open(map, selectedMarker);

        return () => {
            infoWindow.close(() => null);
        }

    }, [selectedMarker, map, selected]);


    return (
        <div className='position-relative' style={{ height: '400px', width: '100%' }}>
            <div id="mapView" style={{ height: '100%' }}></div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCnNf6kIq3DO1pSmHlP3qlehZgeaibm4oM',
})(GoogleMap); 
