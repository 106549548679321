import { Row, Col, Card, Button, Skeleton, Image } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { PRICE } from "../../constants/conts";
import { Badge } from 'antd';
import moment from 'moment';
import notfound from "../../assets/images/not_found.png"

function View() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [subscription, setSubscription]       =   useState([]);
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewCustomer + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.getCustomer);
        setSubscription(data.data.getSubscription);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <>
          <Row gutter={16}>
            <Col span={12} xs={24} md={24}>
              <Card title="Customer Details">

              {loading ? <Skeleton active /> :
                <div className="view-main-list">

                  <div className="view-inner-cls">
                    <h5>Image:</h5>
                    <h6><Image width={100} height={100} className="float-right" src={list.image ? list.image : notfound} /></h6> 
                  </div>

                  <div className="view-inner-cls">
                    <h5>Name:</h5>
                    <h6>{list.name}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Email Address:</h5>
                    <h6>{list && list.email ? list.email : -''}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Phone Number:</h5>
                    <h6>{(list.country_code && list.mobile_number ? "+" + list.country_code : '') + (list.mobile_number ? "-" + list.mobile_number : '-') }</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Notification Status:</h5>
                    <h6>{list && list.notification ? 'Yes' : 'No'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Is WhatsApp Number:</h5>
                    <h6>{list && list.is_your_whatsapp_no ? 'Yes' : 'No'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>DOB:</h5>
                    <h6>{list && list.dob ? moment(list.dob).format("d, M Y") : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Gender:</h5>
                    <h6>{list && list.gender ? list.gender : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Address:</h5>
                    <h6>{list && list.address ? list.address : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Status:</h5>
                    <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                  </div>

                  {list && list.is_delete ? 
                    <div className="view-inner-cls">
                      <h5>Account Deleted:</h5>
                      <h6><Badge status="error" text="Deleted" /></h6>
                    </div>
                  : null}

                  <div className="view-inner-cls">
                    <h5>Social Signup:</h5>
                    <h6>{list.login_type ? list.login_type : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Social ID:</h5>
                    <h6>{list.social_id ? list.social_id : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>Registered On:</h5>
                    <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                  </div>

                  <div className="view-inner-cls float-right">
                    <Link className="ant-btn ant-btn-default" to="/customer/">Back</Link>
                  </div>

                </div>
              }

              </Card>
            </Col>
          </Row>

    
        <Row gutter={24}>

          {subscription && subscription.length > 0 ? subscription.map((item, index) => 
            <>
              <Col span={8}>
                <Card className="mt-3 cap" title={'Subscriptions ' + "(" + (item && item.subscription ? item.subscription.name : '') + ")"} bordered={false}>
                  <div className="view-main-list">

                    <div className="view-inner-cls">
                      <h5>Name:</h5>
                      <h6>{item && item.subscription ? item.subscription.name : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Subscription No:</h5>
                      <h6>{item.subscription_no}</h6>
                    </div>

                    {/* <div className="view-inner-cls">
                      <h5>Subscription Type:</h5>
                      <h6>{item.subscription_type}</h6>
                    </div> */}

                    <div className="view-inner-cls">
                      <h5>Car Number:</h5>
                      <h6>{item && item.car_id ? item.car_id.car_number : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>{'Amount ('+PRICE +')'}:</h5>
                      <h6>{item.subscription && item.subscription.discount ? item.subscription.discount : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Purchase On:</h5>
                      <h6>{item.start_date ? moment(item.start_date).format('DD-MMM-YYYY') : '-'}</h6>
                    </div>

                    <div className="view-inner-cls">
                      <h5>Expire On:</h5>
                      <h6>{item.end_date ? moment(item.end_date).format('DD-MMM-YYYY') : '-'}</h6>
                    </div>
                  </div>
                </Card>
              </Col>
            </>
          ) : null}

        </Row>
     
      </>
    </Main>
  );
}


export default View;
