import { Row, Col, Card, Table, Button, Modal, Form, Input, Tag, Tooltip, Select } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import Main from "../../../components/layout/Main";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
import apiPath from "../../../constants/apiPath";
import moment from 'moment';
import ShowTotal from "../../../components/ShowTotal";
import useDebounce from "../../../hooks/useDebounce";
const Search = Input.Search;

function Index() {

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { confirm } = Modal;

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);
  };

  const columns = [
    /* {
      title: "Brand",
      dataIndex: "make",
      key: "make",
      render: (_, {make}) => {
        return ( make ? make.name : '-')
      }
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (_, {model}) => {
        return ( model ? model.name : '-')
      }
    }, */
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a> );
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
          <Tooltip title="Update Type" color={"purple"} key={"updatetype"}>
            <Button onClick={() => {
              setSelected(record)
              setVisible(true)
            }}>
              <i class="fa fa-light fa-pen"></i>
            </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];


  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusCarType + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: apiPath.listCarType + `?status=${filterActive ? filterActive.join(',') : ''}&search=${debouncedSearchText}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Vehicle Type"
              extra={
                <>
                <Search
                  size="large"
                  onChange={onSearch}
                  value={searchText}
                  onPressEnter={onSearch}
                  placeholder="Search By Name"
                />
                <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add Vehicle Type</Button>
                </>
              }
            >

                <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
               <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form]                            = Form.useForm();
  const { request }                       = useRequest()
  const [loading, setLoading]             = useState(false)
  const [makeLoading, setMakeLoading]     = useState(false);
  const [makeList, setMakeList]           = useState([]);
  const [modelList, setModelList]         = useState([]);
  const [modelLoading, setModelLoading]   = useState([]);

  /* const getVehicleMakeList = () => {
    setMakeLoading(true);
    request({
      url: apiPath.carMakeList,
      method: 'GET',
      onSuccess: (data) => {
        setMakeList(data.data);
        setMakeLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const handleMakeChange = (value) => {
    getVehicleModelList(value)
  }

  const getVehicleModelList = (make_id) => {
    setModelLoading(true);
    request({
      url: apiPath.carModelList + "/" + make_id,
      method: 'GET',
      onSuccess: (data) => {
        setModelList(data.data);
        setModelLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }; */

  useEffect(() => {
   // getVehicleMakeList();
    if (!data) return
    //getVehicleModelList(data.make_id)
    form.setFieldsValue({ ...data })
  }, [data])

  const onCreate = (values) => {
    const { name, make_id, model_id } = values
    const payload = {}
    setLoading(true)
    payload.name = name;
    payload.make_id = make_id;
    payload.model_id = model_id;
    request({
      url: `${data ? apiPath.addEditCarType + "/" + data._id : apiPath.addEditCarType}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Vehicle Type' : 'Create a New Vehicle Type'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>

          {/* <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="make-elt"
              name="make_id"
              label="Select Brand"
              rules={[{ required: true, message: 'Missing Brand Selection' }]}
            >

              <Select onChange={handleMakeChange} placeholder="Select Brand">
                {makeLoading ? <option disabled> Please Wait... Wa Are Fatching Models </option> :
                  <>
                    {makeList && makeList && makeList.length > 0 ? makeList.map((item, index) => (
                        <option key={index} value={item._id}>
                            <span className="cap"> {item.name} </span>
                        </option>
                    )) : null}
                  </>
                }
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="model-elt"
              name="model_id"
              label="Select Model"
              rules={[{ required: true, message: 'Missing Model Selection' }]}
            >

              <Select placeholder="Select Model">
                {modelLoading ? <option disabled> Please Wait... Wa Are Fatching Models </option> :
                  <>
                    {modelList && modelList && modelList.length > 0 ? modelList.map((item, index) => (
                        <option key={index} value={item._id}>
                            <span className="cap"> {item.name} </span>
                        </option>
                    )) : null}
                  </>
                }
              </Select>
            </Form.Item>
          </Col> */}

          <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item
              label="Type"
              name="name"
              rules={[
                { required: true, message: "Please Enter the type!" },
                { max: 50, message: "Name should not contain more then 50 characters!" },
                { min: 2, message: "Name should contain atleast 1 characters!" },
              ]}
            >
              <Input placeholder="Enter Type Name"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
