import React from "react";
import Main from "../components/layout/Main";

function PrivecyPolicy() {
  return (
    <Main>
      <div className="container d-flex justify-content-center align-items-center">
        <div class="bg-light-gray">
          <div class="row p-5">
            <div class="col-12 ">
              <p dir="LTR">
                Out of commitment to secure our customers’ data and information
                privacy on the internet, we in{" "}
                <strong>Lamsa</strong> did develop a set of items
                would explain our policy to maintain our customers’ privacy and
                the way we deal with their personal data.
              </p>
              <p dir="LTR">
                We use information and data our customers provide by their own
                volition; data provided by its owner. We&nbsp;do not collect
                personal data of our customers who surf our website; we only
                keep the data they give.
              </p>
              <p dir="LTR">
                It is well known that by visiting any website,{" "}
                <strong>
                  <a href="https://lamsa.app/lander">lamsa.app</a>
                </strong>{" "}
                website is included, the host server logs the Internet Protocol
                address /IP/ of the visitor device along with the date and time
                of entrance, as well as the browser type, and the address of the
                external referring site.
              </p>
              <p dir="LTR">
                All websites, including{" "}
                <strong>
                  <a href="https://lamsa.app/lander">lamsa.app</a>
                </strong>
                &nbsp;website<strong>,&nbsp;</strong>contain external links to
                other sites. We are not responsible for the privacy policy and
                the content of other sites. However, we recommend our dear
                customers to read the privacy policies of those sites before
                surfing.
              </p>
              <p dir="LTR">
                We are committed, all the time, to maintain the privacy and
                confidentiality of all the personal data and information our
                customers provided. Unless it is required by a law or a new
                legislation, or when we believe (with no bad faith) that such
                action will be required or desirable by the law, or to defend
                our customers, or to protect the private ownership rights of Net
                property.
              </p>
              <p dir="LTR">
                In case of need any of our consumers’ data that may help us to
                contact them to meet their requests, we shall contact them
                directly for that. Provided data shall never be disclosed to any
                third party for the purpose of marketing for his own benefit
                unless we obtain the customer’s prior written consent. We could
                ask for data to be used for statistical research purposes of{" "}
                <a href="https://lamsa.app/lander">
                  <strong>lamsa.app.</strong>
                </a>
                &nbsp;These data would not contain anything to identify the
                customer.
              </p>
              <p dir="LTR">
                Moreover, provided data shall be used in answering customers’
                questions, comments, and requests by{" "}
                <a href="https://lamsa.app">
                  <strong>lamsa.app&nbsp;</strong>
                </a>
                website team or any of its websites teams.
              </p>
              <p dir="LTR">
                We will not sell, barter, rent, or disclose any information to
                any other party outside this website, or its websites. We will
                disclose information only in the case of an order to do so by a
                legal or regulatory authority.
              </p>
              <p dir="LTR">
                <a href="https://lamsa.app" target="_blank" rel="noreferrer">
                  <strong>lamsa.app</strong>
                </a>{" "}
                preserves the right to amend, if necessary or when appropriate,
                the terms and conditions of information privacy policy and
                confidentiality.
              </p>
              <p dir="LTR">
                Our customers shall always be notified what data we have
                obtained, how it shall be used, and who shall receive this data.
              </p>
              <p dir="LTR">
                When needed or necessary, we are pleased to receive customers’
                calls on the numbers can be found in <strong>Contact Us</strong>{" "}
                tab or to receive emails on
                <a href="mailto:info@lamsapro.app">
                  <strong>info@lamsapro.app</strong>
                </a>
                .
              </p>
              <p dir="LTR">
                Customers’ caution and fears regarding the confidentiality and
                privacy of their data is very important to us, and we hope we
                have maintained it through this clear and explicit policy.
              </p>
              <p dir="LTR">
                If any customer wants to delete his data from us, he can contact
                this email{" "}
                <a href="mailto:info@lamsapro.app">
                  <strong>info@lamsapro.app</strong>
                </a>{" "}
                &nbsp;and we will delete all his data
              </p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}

export default PrivecyPolicy;
