import { Row, Col, Card, Table, Button, Image, Modal, Form, Input, Tag, Space, DatePicker, Tooltip } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import useDebounce from "../../hooks/useDebounce";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import { Link } from "react-router-dom";
import { PRICE } from "../../constants/conts";
const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const { confirm } = Modal;
  const navigate = useNavigate();

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);

  };

  const view = (record) => {
    navigate(`/customer/view/${record._id}`)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return name ? <Link className="cap" to={`/customer/view/${_id}`}>{name}</Link> : '-';
      },
    },
    {
      title: "Mobile Number",
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code && mobile_number ? "+" + country_code : '') + ( mobile_number ? "-" + mobile_number : '-')
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? email : '-';
      },
    },
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, is_delete, _id }) => {
        let color = is_active ? 'green' : 'red';
        return ( 
          <> 
            {!is_delete ? <a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"} </Tag></a> : is_delete ? "Deleted" : null }
          </> 
        );
      },
    },
    {
      title: "Job Running",
      render: (_, { job_running }) => {
        return ( job_running ? job_running : 0 );
      },
    },
    {
      title: "Paid Amount ("+PRICE +")",
      render: (_, { amount_pay }) => {
        return ( amount_pay ? amount_pay : 0 );
      },
    },
    {
      title: "Registered On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            {!record.is_delete ? 
              <Tooltip title="Update Customer" color={"purple"} key={"updatecustomer"}>
                <Button title="Edit" onClick={() => {
                  setSelected(record)
                  setVisible(true)
                }}>
                  <i class="fa fa-light fa-pen"></i>
                </Button>
              </Tooltip>
            : null }

            <Tooltip title="View Customer" color={"purple"} key={"viewcustomer"}>
              <Button title="View" onClick={(e) => view(record)}>
                <i class="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];


  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusCustomer + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };


  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: apiPath.listCustomer + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };


  const handleChangeDate = (e) => {
    if(e!=null){
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    }else{
      setStartDate()
      setEndDate()
    }
  };

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Customer Management"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder="Search By name, email, mobile"
                  />
                  <RangePicker onChange={handleChangeDate} />
                  <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add Customer</Button>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50']}}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [mobileNumber, setMobileNumber] = useState({
    mobile: '',
    country_code: '',
  });
  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const handleChange = (value, data, event, formattedValue) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }
  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ name : data.name, email : data.email })
    setMobileNumber({
      mobile: data.mobile_number,
      country_code: data.country_code
    })
  }, [data])

  const onCreate = (values) => {
    const { name, email, password } = values
    const payload = {}

    if (!mobileNumber.mobile) return ShowToast('Please enter mobile number', Severty.ERROR)

    if ((mobileNumber.mobile).length < 8) {
      return ShowToast('Mobile number should be greater then 8 digits', Severty.INFO);
    }

    setLoading(true)
    payload.country_code = mobileNumber.country_code;
    payload.mobile_number = mobileNumber.mobile;
    payload.name = name;
    payload.email = email;
    payload.password = password;
    request({
      url: `${data ? apiPath.addEditCustomer + "/" + data._id : apiPath.addEditCustomer}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        if(error.response.data.status == 400){
          hide();
          refresh();
          ShowToast(error.response.data.message, Severty.WARNING)
        }else{
          ShowToast(error.response.data.message, Severty.ERROR)
        }
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Customer' : 'Create a New Customer'}`}
      okText="Ok"
      width={800}
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>

          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please Enter the name!" },
                { max: 50, message: "Name should not contain more then 50 characters!" },
                { min: 2, message: "Name should contain atleast 1 characters!" },
                { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "Only Alphabetic Characters Allowed" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name"/>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email', message: 'The enter is not valid e-mail!',
                },
                { required: true, message: "Please enter the email!" }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address"/>
            </Form.Item>

          </Col>

          <Col span={12}>
            <Form.Item label="Mobile Number">
              <PhoneInput inputProps={{
                name: "mobile",
                required: true,
                autoFocus: false,
                placeholder: "Enter Mobile Number"
              }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"om"} value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+968") + (mobileNumber.mobile ? mobileNumber.mobile : null)) : '+968'} onChange={handleChange} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item className="password" label="Password" name="password"
              rules={[
                { min: 6, message: "Password should contain at least 6 characters!" },
                { required: !data, message: "Please enter a password!" },
              ]}
            >
              <Input.Password onCut = {(e) => e.preventDefault()} onCopy = {(e) => e.preventDefault()} onPaste = {(e) => e.preventDefault()} autoComplete='off' placeholder="Enter Password" />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
