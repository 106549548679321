import { Row, Col,Form, Modal, Select, Skeleton, Badge } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";

export const AssignWasher = ({ show, hide, data, refresh }) => {
  const [forms] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [loadingModel, setLoadingModel] = useState(false)
  const [washerList, setWasherList] = useState([])

  const getwasherList = () => {
    setLoadingModel(true)
    request({
      url: apiPath.activeWasherList,
      method: 'GET',
      onSuccess: (data) => {
        setLoadingModel(false)
        setWasherList(data.data);
      },
      onError: (error) => {
        setLoadingModel(false)
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  useEffect(() => {
    getwasherList();
    if (!data) return
    forms.setFieldsValue({ ...data })
  }, [data])

  const onCreate = (values) => {
    const { washer_id } = values
    const payload = {}
    setLoading(true)
    payload.order_id   = data._id;
    payload.washer_id  = washer_id;
    payload.order_type = data.order_type;
    request({
      url: apiPath.assignWasher,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      width={600}
      title='Assign/Change Washer'
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      {loadingModel ? <Skeleton active /> : 
        <Form id="create" form={forms} onFinish={onCreate} layout="vertical">
          <Row>
            <Col span={24}>
                <Form.Item
                  label="Select Washer"
                  name="washer_id"
                  rules={[
                    { required: true, message: "Please select the washer!" },
                  ]}
                >

                  <Select showSearch placeholder="Select Washer"
                    filterOption={(input, option) =>
                      (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {washerList && washerList.length > 0 ? washerList.map((item) => (
                      <Select.Option key={item._id} value={item._id} disabled={
                          item.is_availability !== 'Available' &&
                          item.is_availability !== 'Busy'
                        }
                        label={item.name}
                      >
                        {item.name} {' '}
                        {item.is_availability === 'Available' ? (<Badge status="success" />) : item.is_availability === 'Busy' ? (<Badge status="error" />) : (<Badge status="default" />)}
                      </Select.Option>
                    )) : null}
                  </Select>

                </Form.Item>
            </Col>
          </Row>
        </Form>
      }
    </Modal>
  )
}


