import { Button, message, Upload, Image } from "antd";
import React, { useState, useEffect } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import { s3Config } from "../config/s3Config";

const SingleImageUpload = ({ fileType, imageType, btnName, onChange }) => {

    const [file, setFile] = useState([]);

    const handleImgChange = async (event) => {
        const { file }      = event;
        setFile([file]);
        uploadFile(file, s3Config(imageType)).then(data => {
            const fileData  = {
                uid         : file.uid,
                name        : file.name,
                status      : 'done',
                url         : data.location,
                thumbUrl    : data.location,
            }
            setFile([fileData]);
            if (onChange) {
                onChange([fileData]);
            }
        }).catch(err => console.error(err));
    };
    
    const beforeUpload = (file) => {
        if (fileType.includes(file.type)) {

        } else {
            message.error("File format is not correct")
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        
        if (!isLt2M) {
            message.error(`Image must be smaller than 5 MB!`)
            return false
        }
        return true
    }

   
    return (
        <Upload listType="picture" maxCount={1} beforeUpload={beforeUpload} customRequest={handleImgChange} onRemove={(e) => setFile([])} fileList={file}>
            {file && file.length > 0 && file !== "" ? null : <Button icon={<UploadOutlined />}>Upload {btnName}</Button>}
        </Upload>
    );
};

export default SingleImageUpload;