import { Row, Col, Card, Button, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from "moment";
import Logo from "../../assets/images/Logo.png";
import callIcon from "../../assets/images/icon/callIcon.svg";
import emailIcon from "../../assets/images/icon/emailIcon.svg";

function View() {

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    request({
      url: apiPath.viewEmailTemplate + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }


  const rawHTML = `
    <html lang="en">
        <body style="font-family: 'Lato', 'Merriweather', 'Roboto', sans-serif;">
            <div class="mainEmailWraper" style="max-width: 680px; margin: 0 auto;">
                <div class="emailHeader" style="display: flex; align-items: center; justify-content: space-between; padding: 16px; background-color: #0089B6; border-radius: 8px 8px 0 0;">
                    <div class="logoOuter" style="display: flex; align-items: center; justify-content: center;">
                        <img src=${Logo} alt="" width="40%" style="filter:brightness(1000);" />
                    </div>
                </div>
        
                <div class="emailTempBody" style="margin-top: 16px;">
                    <div style="padding: 16px; background-color: #eaeeef; gap: 16px;">
                      ${list.description}
                    </div>
                </div>
        
                <div class="emailFooter" style="padding: 16px; background-color: #0089B6; border-radius: 0 0 8px 8px; text-align: center;">
                    <div class="title" style="font-size: 16px; color: #fff; font-weight: 500;">Please Email us or Call us if you have any queries.</div>
                    <div class="contactDetail" style="display: flex; align-items: center; gap: 16px; justify-content: center; margin-top: 8px;">
                        <div class="email" style="display: flex; align-items: center; gap: 4px;">
                            <div class="icon" style="width: 24px; height: 24px;">
                                <img style="width: 100%;" src=${emailIcon} alt="">
                            </div>
                            <a href="mailto:carwash.com" style="text-decoration: none; color: #fff; font-size: 16px;">carwash.com</a>
                        </div>
                        <div class="phone" style="display: flex; align-items: center; gap: 4px;">
                            <div class="icon" style="width: 24px; height: 24px;">
                                <img style="width: 100%;" src=${callIcon} alt="">
                            </div>
                            <a href="tel:+91 9876543210" style="text-decoration: none; color: #fff; font-size: 16px;">+91 9876543210</a>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>`;

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <Card title="Email Template Details">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? [1,2,3].map(item => <Skeleton active key={item} />)  : 
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Title:</h5>
                  <h6>{list.title}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Subject:</h5>
                  <h6>{list.subject}</h6>
                </div>

                {/* <div className="view-inner-cls">
                  <h5>Description:</h5>
                  <h6>{list.description ? <p dangerouslySetInnerHTML={{__html: list.description}}></p>   : "-"}</h6>
                </div> */}

                <div className="view-inner-cls">
                  <h5>Status:</h5>
                  <h6>{list.is_active ? <Badge colorSuccess status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-default" to="/email-template/">Back</Link>
                </div>

              </div>
            }

          </Col>
        </Row>
      </Card>


      {list && list.description ? 
        <Card title="Email Preview" className="mt-3">
          <Row gutter={16}>
            <Col span={12} xs={24} md={24}>

              {loading ? <Skeleton active /> : 
                <div className="view-main-list">
                  <h6>{<p dangerouslySetInnerHTML={{__html: rawHTML}}></p>}</h6>
                </div>
              }

            </Col>
          </Row>
        </Card>
      : null }

    </Main>
  );
}

export default View;
