import { Row, Col, Card, Button, Skeleton, Table } from "antd";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import { PRICE } from "../../constants/conts";
import moment from 'moment';
import ServiceColumns from '../Order/ServiceColumns';
import SubscriptionColumns from '../Order/SubscriptionColumns';


function View() {

  const params                      =   useParams();
  const { request }                 =   useRequest();
  const [list, setList]             =   useState({});
  const [orderList, setOrderList]   =   useState({});
  const [loading, setLoading]       =   useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewTransaction + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
        setOrderList(data.data.order_id);
        console.log(data.data.order_id,"aoifhfg");
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <Main>
      <Card title="Transaction Details">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? [1,2].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Transaction ID:</h5>
                  <h6>{list.transaction_id}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Order ID:</h5>
                  <h6>{list.order_id && list.order_id.random_order_id ? list.order_id.random_order_id : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Order Type:</h5>
                  <h6 className="cap">{list.type}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Washer Assign:</h5>
                  <h6>{list.washer_id && list.washer_id.name ? <Link className="cap" to={`/washer/view/${list.washer_id._id}`}>{list.washer_id.name}</Link> : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Discount Code:</h5>
                  <h6>{list.dicount_code ? list.dicount_code : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{'Total Amount ('+PRICE +')'}:</h5>
                  <h6>{list.total}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Payment Status:</h5>
                  <h6>{list.payment_status === 'paid' ? <Badge status="success" text="Paid" /> : list.payment_status === 'cancel' ? <Badge status="error" text="Cancel" /> : <Badge status="warning" text="Pending" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Transaction On:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-default" to="/transaction/">Back</Link>
                </div>

              </div>
            }
          </Col>
        </Row>
      </Card>

      <Card title="On Demand Services" className="mt-3">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Table
                  loading={loading}
                  columns={ServiceColumns}
                  dataSource={orderList && orderList.service_id}
                  className="ant-border-space"
                />
              </div>
            }
          </Col>
        </Row>
      </Card>

      <Card title="Subscription Services" className="mt-3">
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>
            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Table
                  loading={loading}
                  columns={SubscriptionColumns}
                  dataSource={orderList && orderList.subscription && orderList.subscription.services}
                  className="ant-border-space"
                />
              </div>
            }
          </Col>
        </Row>
      </Card>


    </Main >
  );
}


export default View;
