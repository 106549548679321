import { Row, Col, Card, Table, Button, Modal, Form, Image, Upload, Input, Tag, message, Tooltip,Select } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import Main from "../../../components/layout/Main";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import apiPath from "../../../constants/apiPath";
import moment from 'moment';
import useDebounce from "../../../hooks/useDebounce";
import { UploadOutlined } from '@ant-design/icons';
import ShowTotal from "../../../components/ShowTotal";
import { uploadFile } from 'react-s3';
import notfound from "../../../assets/images/not_found.png"
import ImportForm from "../../../components/ImportForm";

const Search = Input.Search;

const S3_BUCKET = 'carwash-s3';
const REGION = 'eu-north-1';
const ACCESS_KEY = 'AKIA5FTZEKCY6QNUX77Q';
const SECRET_ACCESS_KEY = '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';

const config = {
  dirName: "brand",
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}

function Index() {

  const { request } = useRequest()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const { confirm } = Modal;
  const [importVisible, setImportVisible] = useState(false);

  const showConfirm = (record) => {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <Button >Are you sure you want change the status ?</Button>,
        onOk() {
          statusChange(record)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }, 5);

  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => <Image width={60} height={50} src={image ? image : notfound} />
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => name ? <span className="cap">{name}</span> : '-'
    }, 
    /* {
      title: "Type",
      dataIndex: "type",
      render: (_, { type }) => type?.name ?? "-"
    }, */
    {
      title: "Status",
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (<a><Tag onClick={(e) => showConfirm(_id)} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MM-YYYY')
        );
      },
    },
    {
      title: "Action",
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title="Update Brand" color={"purple"} key={"updatebrand"}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i class="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];


  const statusChange = (record) => {
    setLoading(true);
    request({
      url: apiPath.statusCarBrand + "/" + record,
      method: 'GET',
      onSuccess: (data) => {
        setRefresh(prev => !prev)
        setLoading(false);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    request({
      url: apiPath.listCarBrand + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  return (
    <Main>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Vehicle Brand"
              extra={
                <>
                  <Search
                    size="large"
                    onChange={onSearch}
                    onPressEnter={onSearch}
                    value={searchText}
                    placeholder="Search By Name"
                  />
                  <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i class="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button>
                  <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>Add Vehicle Brand</Button>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {importVisible && <ImportForm path={apiPath.importBrand} sectionName={'Brand'} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </Main>
  );
}

const AddFrom = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [type, setType] = useState([]);
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"]

  useEffect(() => {
    // getType();
    if (!data) return
    form.setFieldsValue({ ...data })
    data.image ? setFile([data.image]) : setFile([]);
    data.image ? setImage([data.image]) : setImage([]);
  }, [data])

  /* const getType = () => {
    request({
      url: apiPath.listCarType,
      method: 'GET',
      onSuccess: (data) => {
        setType(data.data.list.docs)
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  } */

  const handleImgChange = async (event) => {
    const { file } = event
    setFile([file])
    uploadFile(file, config)
      .then(data => {
        const fileData = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: data.location,
          thumbUrl: data.location,
        }
        setFile([fileData])
      }
      )
      .catch(err => console.error(err))
  };

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error("File format is not correct")
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(`Image must be smaller than 5 MB!`)
      return false
    }
    return true
  }

  const onCreate = (values) => {
    const { name, type } = values
    const payload = { type }
    setLoading(true)
    payload.name = name;
    payload.image = file.length > 0 ? file[0].url : '';
    request({
      url: `${data ? apiPath.addEditCarBrand + "/" + data._id : apiPath.addEditCarBrand}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };

  return (
    <Modal
      visible={show}
      title={`${data ? 'Update Vehicle Brand' : 'Create a New Vehicle Brand'}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label="Brand"
              name="name"
              rules={[
                { required: true, message: "Please Enter the brand!" },
                { max: 50, message: "Brand should not contain more then 50 characters!" },
                { min: 2, message: "Brand should contain atleast 1 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Brand Name" />
            </Form.Item>
          </Col>

          {/* <Col span={24} xs={24} sm={24} md={24}>
            <Form.Item className="make-elt"
              name="type"
              label="Select Vehicle Type"
              rules={[{ required: true, message: 'Missing Vehicle Type Selection' }]}
            >
              <Select placeholder="Select Vehicle Type">
                {
                  type.map((item, index) => (
                    <option key={index} value={item._id}>
                      <span className="cap"> {item.name} </span>
                    </option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <Form.Item label="Upload Image" name="image"
              rules={[
                {
                  validator: () => {
                    if (file !== undefined && file?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Brand image is required"));
                  },
                },
              ]}
            >
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleImgChange}
                onRemove={(e) => { setFile([]); setImage([]); }}
                fileList={file}
              >

                {file && file.length > 0 ? null : <Button icon={<UploadOutlined />}>Upload Image</Button>}

              </Upload>

              {image && image.length > 0 ? <div className="mt-3"> <Image src={image} /> </div> : null}

            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  )
}

export default Index;
