import { Row, Col, Card, Skeleton} from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { useParams } from "react-router-dom";
import apiPath from "../../constants/apiPath";
import { ShowToast, Severty } from "../../helper/toast";
import moment from "moment";

const Cancel = () => {
    
    const params                    =   useParams();
    const { request }               =   useRequest()
    const [list, setList]           =   useState({});
    const [loading, setLoading]     =   useState(false);

    const fetchData = (id) => {
        setLoading(true);
        request({
          url: apiPath.paymentOrderView + "/" + id,
          method: 'GET',
          onSuccess: (data) => {
            setLoading(false);
            setList(data.data);
          },
          onError: (error) => {
            ShowToast(error, Severty.ERROR)
          }
        })
    }

    const updatePaymentData = (order_id) => {
        const payload = {}
        payload.order_id    =   order_id;
        request({
          url   : apiPath.updatePaymentOrder,
          method: 'POST',
          data  : payload,
          onSuccess: (data) => {
            console.log("success");
          },
          onError: (error) => {
            console.log("error", error);
          },
        })
    };

    useEffect(() => {
        fetchData(params.order_id)
        updatePaymentData(params.order_id)
    }, [])
    
    return (
        <div className="paymentMain h-100 d-flex align-items-center justify-content-center">
            <Row className="justify-content-center">
                <Col span={24} lg={12} className="">
                    <Card className="paymentCard paymentCancel">
                        {loading ? [1,2].map(item => <Skeleton active key={item} />) :
                            <>
                                <div className="cancel-checkmark">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className="error">
                                        <circle className="solid" fill="none" stroke-linecap="round" stroke-width="4" stroke-miterlimit="10" cx="32" cy="32" r="30"/>
                                        <circle className="animation" fill="none" stroke-linecap="round" stroke-width="4" stroke-miterlimit="10" cx="32" cy="32" r="30"/>
                                        <line className="left" fill="none" stroke="#000000" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" x1="19.271" y1="19.521" x2="44.729" y2="44.979"/>
                                        <line className="right" fill="none" stroke="#000000" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" x1="44.729" y1="19.521" x2="19.271" y2="44.979"/>
                                    </svg>
                                </div>
                                <div className="mainTagLine">Payment Cancel!</div>
                                <div className="tagLine">Oops! Your Payment of OMR {list.total} has been canceled.</div>
                                <div className="orderId">Order ID : {list && list.random_order_id} &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; Order Type : {list && list.order_type}</div>
                                <div className="orderId">Cancelled Date : {list && list.created_at ? moment(list.created_at).format('DD-MM-YYYY') : null }</div>
                            </>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

// window.addEventListener('load', function() {
//     document.querySelector("svg.error").classList.toggle("animate");
// });

export default Cancel;